import I18n from "i18n-js";
import PropTypes from "prop-types";

import EthnicityWrapper from "./EthnicityWrapper";
import RaceWrapper from "./RaceWrapper";

/** ### Usage
 *
 * A component for collecting how a person identifies their races and ethnicities
 * As we are transitioning from an old ethnicities object to a new schema in which
 * ethnicities and races are two categories, this also handles that migration by
 * basing whether to show the old vs new UI based on the useRacesInput argument.
 */
const EthnicityAndRaceWrapper = ({
  useRacesInput,
  selectedEthnicities,
  ethnicitiesInputName,
  isRequired = false,
  ethnicitiesDescription,
  racesDescription,
  isAfcarsRequired = false,
  selectedRaces = null,
  racesInputName = null,
}) => (
  <div>
    <If condition={useRacesInput}>
      <EthnicityWrapper
        selectedEthnicities={selectedEthnicities}
        inputName={ethnicitiesInputName}
        labelText={I18n.t("views.common.of_hispanic_origin_title")}
        ethnicityOptions={window.Binti.NEW_ETHNICITY_OPTIONS}
        description={ethnicitiesDescription}
        isRequired={isRequired}
        isAfcarsRequired={isAfcarsRequired}
      />
      <RaceWrapper
        selectedRaces={selectedRaces}
        inputName={racesInputName}
        description={racesDescription}
        isRequired={isRequired}
      />
    </If>
    <If condition={!useRacesInput}>
      <EthnicityWrapper
        selectedEthnicities={selectedEthnicities}
        ethnicityOptions={window.Binti.LEGACY_ETHNICITY_OPTIONS}
        labelText={I18n.t("views.common.ethnicity")}
        inputName={ethnicitiesInputName}
        isRequired={isRequired}
        isAfcarsRequired={isAfcarsRequired}
      />
    </If>
  </div>
);

EthnicityAndRaceWrapper.propTypes = {
  useRacesInput: PropTypes.bool.isRequired,
  selectedEthnicities: PropTypes.object.isRequired,
  ethnicitiesInputName: PropTypes.string.isRequired,
  ethnicitiesDescription: PropTypes.string,
  racesDescription: PropTypes.string,
  isRequired: PropTypes.bool,
  /** Whether these inputs are required for AFCARS completeness */
  isAfcarsRequired: PropTypes.bool,
  racesInputName: PropTypes.string,
  selectedRaces: PropTypes.object,
};

export default EthnicityAndRaceWrapper;
