import {
  FAMILY_FINDING_CONTACT_LOG_STATUS_ATTEMPTED,
  FAMILY_FINDING_CONTACT_LOG_STATUS_RETURNED,
  FAMILY_FINDING_CONTACT_LOG_STATUS_SUCCESSFUL,
  FAMILY_FINDING_CONTACT_LOG_STATUS_SENT,
  FAMILY_FINDING_CONTACT_LOG_STATUS_DELIVERED,
  FAMILY_FINDING_CONTACT_LOG_STATUS_OPENED,
  FAMILY_FINDING_CONTACT_LOG_STATUS_SOFT_BOUNCED,
  FAMILY_FINDING_CONTACT_LOG_STATUS_HARD_BOUNCED,
} from "@root/constants";

const contactStatusMap = {
  [FAMILY_FINDING_CONTACT_LOG_STATUS_ATTEMPTED]: "alert",
  [FAMILY_FINDING_CONTACT_LOG_STATUS_RETURNED]: "warning",
  [FAMILY_FINDING_CONTACT_LOG_STATUS_SUCCESSFUL]: "success",
  [FAMILY_FINDING_CONTACT_LOG_STATUS_SENT]: "alert",
  [FAMILY_FINDING_CONTACT_LOG_STATUS_DELIVERED]: "alert",
  [FAMILY_FINDING_CONTACT_LOG_STATUS_OPENED]: "success",
  [FAMILY_FINDING_CONTACT_LOG_STATUS_SOFT_BOUNCED]: "warning",
  [FAMILY_FINDING_CONTACT_LOG_STATUS_HARD_BOUNCED]: "warning",
};

export default contactStatusMap;
