import classNames from "classnames";
import { sum } from "lodash";
import PropTypes from "prop-types";
import { useMemo, useRef } from "react";

import styles from "./VoicePulser.module.scss";

// this function helps to magnify small values to be more visible
// when setting the volume indicator radius. volumes tend to be
// be small but can spike to be large, so this helps compensate
// for that and make the volume indicator more visible when people
// are speaking softly.
//
// https://math.stackexchange.com/a/158515
const factor = 500;
const smallValueMagnifier = (value, n = factor) =>
  ((n + 1) * value) / (n * value + 1);

// this value corresponds to the maximum radius of the inner circle
// gradient. the circle as a whole is transparent at the edges, so
// having a maximum 90% of the inner circle lets us feather the edge.
const maxInnerColorRadius = 0.9;

/**
 * React hook for displaying a circular pulsing effect that responds
 * to the volume of audio input.
 */
const useVolumePulser = () => {
  const containerRef = useRef();

  const onAudioActivity = useMemo(() => {
    // using a closure instead of state since these change on every
    // animation frame when audio is active
    //
    // 100 is derived from the values we saw in testing, ranging from
    // ~100-2000. we start here so the jump isn't as jarring when this
    // auto-adjusts.
    let largestVolumeSeen = 100;
    let resetTimeout = null;

    return dataArray => {
      if (!containerRef.current) return;

      const currentVolume = sum(dataArray);
      largestVolumeSeen = Math.max(currentVolume, largestVolumeSeen);

      const newInnerColorRadius = Math.floor(
        smallValueMagnifier(currentVolume / largestVolumeSeen) *
          maxInnerColorRadius *
          100
      );

      // unfortunately react isn't actually fast enough to set this
      // via state and props, so we set this var via a ref for performance
      containerRef.current.style = `--innerColorRadius: ${newInnerColorRadius}%`;
      if (resetTimeout) {
        clearTimeout(resetTimeout);
      }

      // reset the inner color radius after a short delay if there's no
      // more speaking before then.
      resetTimeout = setTimeout(() => {
        containerRef.current.style = "--innerColorRadius: 0%";
      }, 100);
    };
  }, []);

  const PulserContainer = ({ children, buttonContainerClassName }) => (
    <div className={styles.container} ref={containerRef}>
      <div
        className={classNames(styles.buttonContainer, buttonContainerClassName)}
      >
        {children}
      </div>
    </div>
  );
  PulserContainer.propTypes = {
    children: PropTypes.node.isRequired,
    buttonContainerClassName: PropTypes.string,
  };

  return { PulserContainer, onAudioActivity };
};

export default useVolumePulser;
