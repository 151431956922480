import { Flex, GraphQLDataTable, Icons, Link } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment, useMemo } from "react";
import { preventionAgencyServiceReferralPath } from "routes";

import { translationWithRoot } from "@components/T";
import UploadDocumentsButton from "@components/prevention/profile/tabs/UploadDocumentsButton";

import AgencyHumanRequirementReconciliation from "@graphql/queries/prevention/AgencyHumanRequirementReconciliation.graphql";
import AgencyServiceReferralRequirementReconciliation from "@graphql/queries/prevention/AgencyServiceReferralRequirementReconciliation.graphql";

const { t } = translationWithRoot(
  "prevention.profile_page.documents_and_attachments_tab"
);

const associatedRecordColumnConfig = {
  AgencyHuman: {
    columnName: { name: t("associated_service_referral") },
    id: "associatedServiceReferral",
    cell: requirement => {
      const agencyServiceReferral = requirement?.agencyServiceReferral;
      return (
        <Flex row justify="start">
          {agencyServiceReferral ? (
            <Link
              href={preventionAgencyServiceReferralPath(
                agencyServiceReferral.id
              )}
            >
              {agencyServiceReferral.agencyService?.name}
            </Link>
          ) : (
            <span>{t("not_applicable")}</span>
          )}
        </Flex>
      );
    },
  },
  AgencyServiceReferral: {
    columnName: { name: "Associated Child" },
    id: "associatedChild",
    cell: requirement => {
      const childName = requirement?.child?.fullName;
      return (
        <Flex row justify="start">
          {childName ? <span>{childName}</span> : <span>{"--"}</span>}
        </Flex>
      );
    },
  },
};

/**
 * Renders a table of ad-hoc docs for an agency service referral or a prevention child
 * associated with an agency human
 *
 * @param requirementHolderId - the ID of the requirement holder
 * @returns {JSX.Element}
 * @constructor
 */
const DocumentsAndAttachmentsTab = ({
  hideTitle = false,
  requirementHolderId,
  requirementHolderType,
}) => {
  const { columnName, id, cell } =
    associatedRecordColumnConfig[requirementHolderType];

  const columns = useMemo(
    () => [
      {
        columnName: { name: "Document" },
        id: "document",
        cell: data => {
          const uploadedRecordFile = data.records[0].uploadedRecordFiles[0];
          const { filename, downloadUrl, fileId } = uploadedRecordFile;
          return (
            <Flex row gap="100" align="center">
              <Link href={downloadUrl} target="_blank">
                {filename}
              </Link>
              <Icons.FileDownload
                description={t("download", { filename })}
                href={downloadUrl}
                target={`uploadedRecordFile${fileId}`}
              />
            </Flex>
          );
        },
      },
      {
        columnName: { name: "Date Added" },
        id: "dateAdded",
        cell: data => (
          <span>
            {new Date(data.requirement.createdAt).toLocaleDateString("en-US")}
          </span>
        ),
      },
      {
        columnName,
        id,
        cell: data => cell(data.requirement),
      },
    ],
    [cell, columnName, id]
  );

  const queryMapping = {
    AgencyHuman: {
      query: AgencyHumanRequirementReconciliation,
      queryTitle: "agencyHumanRequirementReconciliation",
    },
    AgencyServiceReferral: {
      query: AgencyServiceReferralRequirementReconciliation,
      queryTitle: "agencyServiceReferralRequirementReconciliation",
    },
  };

  const { query, queryTitle } = queryMapping[requirementHolderType];
  let ShrineErrorAlert = () => null;
  let AlertModal = () => null;
  let UploadButton = () => null;

  if (requirementHolderType === "AgencyHuman") {
    const components = UploadDocumentsButton(requirementHolderId);
    ShrineErrorAlert = components.ShrineErrorAlert;
    AlertModal = components.AlertModal;
    UploadButton = components.UploadButton;
  }

  return (
    <Fragment>
      <AlertModal />
      <ShrineErrorAlert />
      <GraphQLDataTable
        title={hideTitle ? null : t("documents_and_attachments_table")}
        columns={columns}
        queryTitle={queryTitle}
        query={query}
        defaultFilters={{ requirementHolderId }}
        filtersToPreserveOnClear={["requirementHolderId"]}
        emptyStatePrompt={t("default_no_data_available")}
        filters={[]}
        actions={
          <Flex column justify="end">
            <UploadButton />
          </Flex>
        }
      />
    </Fragment>
  );
};

DocumentsAndAttachmentsTab.propTypes = {
  hideTitle: PropTypes.bool,
  requirementHolderId: PropTypes.number.isRequired,
  requirementHolderType: PropTypes.oneOf([
    "AgencyHuman",
    "AgencyServiceReferral",
  ]).isRequired,
};

export default DocumentsAndAttachmentsTab;
