import { LoadingOverlay } from "@heart/components";
import * as HeartTable from "@heart/components/table/Table";

const { Td, Tr } = HeartTable;

/** Loading indicator for data tables.  Place within a `<Tbody />`. */
const TableLoadingIndicator = () => (
  <Tr data-testid="table-loading-indicator">
    <Td colSpan={1000}>
      <LoadingOverlay active />
    </Td>
  </Tr>
);
export default TableLoadingIndicator;
