import { Button, Flex, Link } from "@heart/components";
import PropTypes from "prop-types";
import { If } from "tsx-control-statements/components";

import AddConditionalRequirementButton from "./AddConditionalRequirementButton";
import ApplicationPDFButton from "./ApplicationPDFButton";
import styles from "./AuxiliaryActionBar.module.scss";

/**
 * AuxiliaryActionBar component renders action buttons on the caregiver
 * documents v2 (requirements) page for an application.
 */
const AuxiliaryActionBar = ({
  application,
  currentUserBintiAdmin,
  mayGenerateRequirements,
  additionalRequirementsEnabled,
}) => (
  <Flex
    row
    justify="end"
    gap="300"
    className={styles.linkButtonContainer}
    align="start"
  >
    <If condition={mayGenerateRequirements && additionalRequirementsEnabled}>
      <AddConditionalRequirementButton applicationId={application.id} />
    </If>
    <ApplicationPDFButton
      application={application}
      currentUserBintiAdmin={currentUserBintiAdmin}
    />
    {application.type === "RenewalApplication" && (
      <Link
        href={`/admin/applications/${application.spawningApplication.id}/documents`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button variant="secondary">
          {I18n.t("views.documents.docs_for_previous_application")}
        </Button>
      </Link>
    )}
  </Flex>
);

AuxiliaryActionBar.propTypes = {
  application: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // GraphQL serializes as string, Rails views as int
    type: PropTypes.string.isRequired,
    collatedPdf: PropTypes.shape({
      createdAt: PropTypes.string,
      isProcessing: PropTypes.bool,
    }),
    spawningApplication: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]), // GraphQL serializes as string, Rails views as int
    }),
  }),
  currentUserBintiAdmin: PropTypes.bool.isRequired,
  mayGenerateRequirements: PropTypes.bool,
  additionalRequirementsEnabled: PropTypes.bool,
};

export default AuxiliaryActionBar;
