import { useMutation } from "@apollo/client";
import { UploadButton, Alert } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

import ApplicationFormDocuments from "@components/application_requirements_shared/ApplicationFormDocuments";
import OverrideActionModalLauncher from "@components/application_requirements_shared/OverrideActionModalLauncher";
import FillableFormRequirementTitle from "@components/requirements/FillableFormRequirementTitle";
import RequirementStatus from "@components/requirements/RequirementStatus";
import { Td, Tr } from "@components/reusable_ui/Table";
import useUploadRow from "@components/reusable_ui/useUploadRow";

import AttachFormInstanceFiles from "@graphql/mutations/AttachFormInstanceFiles.graphql";
import CreateFormOverride from "@graphql/mutations/CreateFormOverride.graphql";

import { policy, typeEq } from "@lib/graphqlHelpers";

import styles from "./ApplicationRequirementTable.module.scss";

const getFormInstance = records => records.find(typeEq("FormInstance"));

/**
 * Row within the application requirements table (CD2) for a fillable form
 * requirement.
 */
const ApplicationFormRequirementRow = ({ fulfillment, application }) => {
  const [attachFormInstanceFiles] = useMutation(AttachFormInstanceFiles);
  const [createFormOverride] = useMutation(CreateFormOverride);
  const [showAlert, setShowAlert] = useState(false);

  const { requirement } = fulfillment;
  const formInstance = getFormInstance(fulfillment.records);

  const uploadFiles = async files => {
    if (files.length === 0) {
      setShowAlert(true);

      return;
    }

    await attachFormInstanceFiles({
      variables: {
        holderToken: application.holderToken,
        files,
        formInstanceId: formInstance.id,
      },
    });
  };

  const onOverride = ({ reason, details, optionKey, grantDate }) =>
    createFormOverride({
      variables: {
        holderToken: application.holderToken,
        reason,
        details,
        optionKey,
        grantDate,
        overrideKey: fulfillment.overrideKey,
        formSlug: requirement.form.slug,
        userAgencyProfileId: requirement.userAgencyProfile?.id,
      },
    });

  const replaceOverrideRecord = ({
    reason,
    details,
    optionKey,
    grantDate,
    overrideRecord,
  }) =>
    createFormOverride({
      variables: {
        holderToken: application.holderToken,
        reason,
        details,
        optionKey,
        grantDate,
        overrideKey: fulfillment.overrideKey,
        formSlug: requirement.form.slug,
        userAgencyProfileId: requirement.userAgencyProfile?.id,
        overrideRecordIdToReplace: overrideRecord.id,
      },
    });

  const { getTrProps, getInputProps, aroundLoader, isLoading } =
    useUploadRow(uploadFiles);

  const disabled = !formInstance || isLoading;

  return (
    <Fragment>
      <Tr {...getTrProps()}>
        <Td>
          <FillableFormRequirementTitle
            holderToken={application.holderToken}
            {...{ requirement, fulfillment }}
          />

          <If condition={requirement.form.reference && !formInstance}>
            <a href={`/admin/applications/${application.id}/edit_references`}>
              List a reference
            </a>{" "}
            (required before uploading)
          </If>
        </Td>
        <Td>
          <RequirementStatus
            isFulfilled={fulfillment.isFulfilled}
            progressPercentage={fulfillment.reviewedAndUnreviewedProgress}
            unreviewedPercentage={fulfillment.unreviewedPercentage}
          />
        </Td>
        <Td>
          <ApplicationFormDocuments
            disabled={isLoading}
            {...{
              application,
              aroundLoader,
              fulfillment,
              replaceOverrideRecord,
            }}
          />
        </Td>
        <Td>
          <div className={styles.actions}>
            <OverrideActionModalLauncher {...{ fulfillment, onOverride }} />

            {(disabled || policy(formInstance).mayCreateAttachment()) && (
              <UploadButton
                disabled={disabled}
                passedInputProps={getInputProps}
              />
            )}
          </div>
        </Td>
      </Tr>

      <Alert
        hidden={!showAlert}
        isAlert={true}
        title={I18n.t("javascript.components.common.alert")}
        onSubmit={() => setShowAlert(false)}
        submitText={I18n.t("attachments.errors.ok")}
      >
        {I18n.t("attachments.errors.unpermitted_format")}
      </Alert>
    </Fragment>
  );
};

ApplicationFormRequirementRow.propTypes = {
  application: PropTypes.object.isRequired,
  fulfillment: PropTypes.object.isRequired,
};

export default ApplicationFormRequirementRow;
