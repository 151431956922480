import { LiveRegion } from "@heart/components";
import PropTypes from "prop-types";
import React, { Suspense } from "react";

import LogoSpinner from "./LogoSpinner";

/**
 * `react-loading-overlay` isn't compatible with server rendering - it expects that `document` isn't undefined.
 * By lazy-loading this library, it's simply not included in the server-rendering compilation process.
 *
 * https://reactjs.org/docs/code-splitting.html#reactlazy
 */
const ReactLoadingOverlay = React.lazy(() => import("react-loading-overlay"));

const minHeight = "50px";

const EmptyBody = () => <div style={{ height: minHeight }} />;

/**
 * Binti "heart" loading overlay pattern.  When `active` is `true`,
 * the children are desaturated, you cannot interact with the children
 * via mouse or keyboard, and the Binti heart is overlaid on top of the content.
 *
 * ### Jest
 * Note: we globally mock this component in our Jest tests, which means assertions
 * can look for "LoadingOverlayActive" or "LoadingOverlayInactive" to determine
 * whether content is loading.
 */
const LoadingOverlay = ({
  active,
  children,
  backgroundTransparent = false,
}) => (
  <Suspense fallback={null}>
    <LiveRegion updating={active}>
      <ReactLoadingOverlay
        active={active}
        spinner={
          <div style={{ width: "50px", height: "50px" }}>
            <LogoSpinner />
          </div>
        }
        styles={{
          wrapper: base => ({ ...base, height: "100%" }),
          overlay: base => ({
            ...base,
            background: `rgba(255, 255, 255, ${
              backgroundTransparent ? 0 : 0.75
            })`,
            height: "100%",
            minHeight: minHeight,
            minWidth: "50px",
          }),
        }}
      >
        {children || <EmptyBody />}
      </ReactLoadingOverlay>
    </LiveRegion>
  </Suspense>
);

LoadingOverlay.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.node,
  backgroundTransparent: PropTypes.bool,
};

export default LoadingOverlay;
