import { useQuery } from "@apollo/client";
import { Surface, ArrayDataTable } from "@heart/components";
import PropTypes from "prop-types";
import React from "react";

import { translationWithRoot } from "@components/T";

import FamilyFindingClearPersonReportDocuments from "@graphql/queries/FamilyFindingClearPersonReportDocuments.graphql";

import tableColumns, { getUploadDateColumnIndex } from "./tableColumns";

const { t } = translationWithRoot("family_finding.documents.documents_table");

/** A table of family finding related documents */
const DocumentsTable = ({ agencyHumanId, displayAssociatedKin = false }) => {
  const { data, loading } = useQuery(FamilyFindingClearPersonReportDocuments, {
    variables: { agencyHumanId },
  });
  const documents = data?.familyFindingClearPersonReportDocuments || [];

  return (
    <Surface hideTitle title={t("table_title")}>
      <ArrayDataTable
        title={t("table_title")}
        columns={tableColumns({ displayAssociatedKin, documents })}
        data={documents}
        defaultSortAttributes={{
          columnIndex: getUploadDateColumnIndex({ displayAssociatedKin }),
          direction: "DESC",
        }}
        loading={loading}
      />
    </Surface>
  );
};
DocumentsTable.propTypes = {
  agencyHumanId: PropTypes.number.isRequired,
  displayAssociatedKin: PropTypes.bool,
};

export default DocumentsTable;
