import { gql, useMutation, useLazyQuery } from "@apollo/client";
import {
  Breadcrumbs,
  LoadingOverlay,
  Wizard,
  WizardPage,
  WizardFieldset,
} from "@heart/components";
import InputAutocompleteGraphQL from "@heart/components/inputs/InputAutocompleteGraphQL";
import { useMountEffect } from "@react-hookz/web";
import { curry, isEqual, isEmpty, isNil, omitBy, compact } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { translationWithRoot } from "@components/T";
import CopyRelationshipDetailsButton from "@components/family_finding/relationships/CopyRelationshipDetailsButton";
import RelationshipToChildSection from "@components/family_finding/relationships/RelationshipToChildSection";
import {
  relationshipForDB,
  relationshipsForUI,
} from "@components/family_finding/relationships/relationshipConversion";

import CreateOrUpdateAgencyHuman from "@graphql/mutations/CreateOrUpdateAgencyHuman.graphql";
import CreateOrUpdateRelationship from "@graphql/mutations/CreateOrUpdateRelationship.graphql";
import AgencyHumanAutocomplete from "@graphql/queries/AgencyHumanAutocomplete.graphql";
import AgencyHumanDetails from "@graphql/queries/AgencyHumanDetails.graphql";
import AgencyHumanRelationships from "@graphql/queries/AgencyHumanRelationships.graphql";

import AddressesSection from "./AddressesSection";
import {
  valuesFromResponse,
  calculateAgencyHumanPercentage,
  agencyHumanInvalid,
  relationshipsInvalid,
} from "./AgencyHumanFormComputations";
import AgencyHumanSummary from "./AgencyHumanSummary";
import ContactInformationSection from "./ContactInformationSection";
import EthnicityAndICWASection from "./EthnicityAndICWASection";
import PersonalInformationSection from "./PersonalInformationSection";
import SocialMediaSection from "./SocialMediaSection.js";
import {
  genderForUI,
  icwaFieldsForDB,
  icwaFieldsForUI,
  socialMediaLinksForUI,
  socialMediaLinksForDB,
  addressesForUI,
  addressesForDB,
  phoneNumbersForDB,
  phoneNumbersForUI,
} from "./agencyHumanConversion.js";

const { t } = translationWithRoot("agency_human.form");

export const SiblingsQuery = gql`
  query SiblingsQuery($agencyHumanId: ID!) {
    siblings(agencyHumanId: $agencyHumanId) {
      id
      fullName
    }
  }
`;

/** A form for creating or editing an AgencyHuman
 *
 * ## Prop Toggles
 * - agencyHumanId: display page allowing user to edit existing agency human
 * - childAgencyHumanId: display page allowing user to select an agency human,
 * and then display page allowing user to specify relationship between the
 * selected agency human and child
 * - agencyHumanId & childAgencyHumanID: display only the page allowing users
 * to specify relationship between the agency human and child, skipping the
 * agency human details page
 */
export const AgencyHumanForm = ({
  agencyId,
  agencyHumanId,
  originPath,
  childAgencyHumanId,
  childName,
}) => {
  const [ethnicitiesValid, setEthnicitiesValid] = useState(true);
  const [formState, setFormState] = useState({
    /** We're defaulting the value of agencyHuman to an empty string so that the value
     * of the Select Person dropdown is always a controlled input
     * input
     */
    agencyHuman: { id: agencyHumanId, value: agencyHumanId || "" },
  });
  const [editingExistingAgencyHuman, setEditingExistingAgencyHuman] = useState(
    !isNil(agencyHumanId) && isNil(childAgencyHumanId)
  );
  const associatedChildSelected = !isNil(childAgencyHumanId);
  const newAgencyHumanSelected =
    formState.agencyHuman.value === "new_agency_human";

  const existingAgencyHumanSelected =
    !newAgencyHumanSelected && !isEmpty(formState.agencyHuman.value);
  const existingAgencyHumanWithoutEditing =
    existingAgencyHumanSelected && !editingExistingAgencyHuman;
  const editingExistingRelationship =
    !isNil(agencyHumanId) && associatedChildSelected;

  const setFormAttribute = curry((attribute, value) => {
    if (!isNil(value) && !isEqual(value, formState[attribute])) {
      setFormState({ ...formState, [attribute]: value });
    }
  });

  /** When in the context of a child, we also want to document the relationship
   * status of any known siblings of the child
   */
  const [loadSiblingData, { called: siblingsFetched, data: siblingData }] =
    useLazyQuery(SiblingsQuery, {
      variables: { agencyHumanId: childAgencyHumanId },
    });
  if (!siblingsFetched && !isNil(childAgencyHumanId)) loadSiblingData();
  const siblings = [
    { id: childAgencyHumanId, fullName: childName },
    ...(siblingData?.siblings || []).filter(({ id }) => id !== agencyHumanId),
  ];

  /** Query to conditionally populate the Basic Details page
   *
   * In the context of editing an AgencyHuman, we want to pull the information we
   * currently know about the AgencyHuman and populate the form with that information
   * so that the user understands what information is already on file and can make
   * changes as they see fit
   *
   * This query is NOT called during the create flow, or when an existing agency human
   * is selected during the create flow. Those details are pulled in via the
   * `valuesFromResponse` processing for our `InputAutocompleteGraphQL`
   */
  const [
    loadAgencyHumanDetails,
    { called: agencyHumanFetched, loading: agencyHumanLoading },
  ] = useLazyQuery(AgencyHumanDetails, {
    variables: { ids: [formState.agencyHuman.id] },
    onCompleted: data => {
      const agencyHuman = data.agencyHumanDetails[0];
      setFormState({
        ...formState,
        ...omitBy(agencyHuman, isNil),
        ...icwaFieldsForUI(agencyHuman),
        ...genderForUI(agencyHuman),
        ...socialMediaLinksForUI(agencyHuman),
        ...addressesForUI(agencyHuman),
        ...phoneNumbersForUI(agencyHuman),
        agencyHuman: {
          tiedToEntity: agencyHuman.tiedToEntity,
          fullName: agencyHuman.fullName,
          label: agencyHuman.fullName,
          value: formState.agencyHuman.id,
          id: formState.agencyHuman.id,
        },
      });
    },
  });

  /** Query to conditionally populate the Relationships to Children page */
  const [
    loadAgencyHumanRelationships,
    {
      data: relationshipData,
      called: relationshipsFetched,
      loading: relationshipsLoading,
    },
  ] = useLazyQuery(AgencyHumanRelationships, {
    variables: {
      agencyHumanId: formState.agencyHuman.id,
      filterByAgencyHumanIds: siblings.map(({ id }) => id),
    },
  });
  useEffect(() => {
    setFormState(oldFormState => ({
      ...oldFormState,
      ...relationshipsForUI({
        data: relationshipData,
        keystoneAgencyHumanId: formState.agencyHuman.id,
      }),
    }));
  }, [formState.agencyHuman.id, relationshipData]);

  /** Conditional statements for calling the above queries */
  if (
    !agencyHumanFetched &&
    (editingExistingAgencyHuman || editingExistingRelationship)
  )
    loadAgencyHumanDetails();
  if (!relationshipsFetched && editingExistingRelationship)
    loadAgencyHumanRelationships();

  /** Mutation called at the end of the Basic Details page */
  const [createOrUpdateAgencyHuman] = useMutation(CreateOrUpdateAgencyHuman, {
    onCompleted: ({
      createOrUpdateAgencyHuman: {
        agencyHuman: { id },
      },
    }) => {
      setFormState({
        ...formState,
        agencyHuman: { ...formState.agencyHuman, id },
      });
      setEditingExistingAgencyHuman(true);
    },
  });
  /** Mutation called at the end of the Relationships to Children page */
  const [createOrUpdateRelationship] = useMutation(CreateOrUpdateRelationship);

  /** When not editing an existing agency human, we want to set any multi input values to
   * empty arrays as that signals to the MultiInputTemplate that we're not waiting on data
   * to load
   */
  useMountEffect(() => {
    if (!editingExistingAgencyHuman) {
      setFormState({
        ...formState,
        emailAddresses: [],
        phoneNumbers: [],
        addresses: [],
        socialMediaLinks: [],
      });
    }
  });

  const verb =
    editingExistingAgencyHuman || editingExistingRelationship
      ? "edit_object"
      : "add_object";
  const object = associatedChildSelected ? t("relationship") : t("connection");
  const title = t(verb, { object });

  let agencyHumanPagePrimaryText;
  if (existingAgencyHumanWithoutEditing) {
    agencyHumanPagePrimaryText = t("continue");
  } else if (associatedChildSelected) {
    agencyHumanPagePrimaryText = t("save_and_add_relationship_details");
  } else {
    agencyHumanPagePrimaryText = t("save_person");
  }

  const saveRelationships = async () => {
    await Promise.all(
      siblings.map(({ id }) =>
        createOrUpdateRelationship({
          variables: relationshipForDB({
            associatedChildAgencyHumanId: id,
            keystoneAgencyHumanId: formState.agencyHuman.id,
            nonKeystoneAgencyHumanId: id,
            ...omitBy(formState[id], isNil),
          }),
        })
      )
    );
  };

  const clearedRelationships = () =>
    siblings.reduce(
      (clearedRels, { id }) => ({
        ...clearedRels,
        [id]: { nonKeystoneAgencyHumanId: id },
      }),
      {}
    );

  return (
    <LoadingOverlay active={agencyHumanLoading}>
      <Wizard
        breadcrumbs={
          <Breadcrumbs
            pages={[
              editingExistingAgencyHuman &&
                !editingExistingRelationship &&
                !associatedChildSelected && {
                  label: formState.fullName || "",
                  href: originPath,
                },
              associatedChildSelected && {
                label: childName,
                href: originPath,
              },
              {
                label: title,
                href: "#",
              },
            ].filter(Boolean)}
          />
        }
        title={title}
        pages={[
          <If
            key="agency_human_details"
            condition={!editingExistingRelationship}
          >
            <WizardPage
              pageTitle={t("pages.basic_details")}
              confirmBeforeCancel
              actionsProps={{
                cancelHref: originPath,
                primaryAction: async () => {
                  /** If a user goes back to creating a new agency human after selecting an existing agency
                   * human (when not editing that existing agency human), we retain the current state of the
                   * form for them so that previously entered information is still present. To ensure that
                   * state does not overwrite the information associated with the selected agency human, we
                   * make the onSubmit a no-op
                   */
                  if (!existingAgencyHumanWithoutEditing)
                    await createOrUpdateAgencyHuman({
                      variables: {
                        ...formState,
                        agencyId,
                        agencyHumanId: formState.agencyHuman.id,
                        childAgencyHumanIds: compact([childAgencyHumanId]),
                        ...icwaFieldsForDB(formState),
                        ...socialMediaLinksForDB(formState),
                        ...addressesForDB({ ...formState, agencyId }),
                        ...phoneNumbersForDB(formState),
                      },
                    });

                  if (associatedChildSelected) loadAgencyHumanRelationships();
                  else window.location = originPath;
                },
                primaryText: agencyHumanPagePrimaryText,
                primarySubmittingText: t("submitting"),
                primaryDisabled:
                  /** Disable submit if:
                   * - We're in the create relationships flow, indicated by not being handed an agencyHumanId
                   * in our props
                   * - We don't have a selected value for the first dropdown indicating whether we are creating
                   * a new agency human or selecting an existing one to define relationships for
                   * - The ethnicities entered are invalid
                   * - One of the complex interdependent fields is invalid, e.g. we have multiple primary emails
                   */
                  (isNil(agencyHumanId) &&
                    isNil(formState.agencyHuman?.value)) ||
                  agencyHumanInvalid({ formState, ethnicitiesValid }),
              }}
              progress={
                existingAgencyHumanWithoutEditing
                  ? 100
                  : calculateAgencyHumanPercentage(formState)
              }
            >
              <If condition={!editingExistingAgencyHuman}>
                <WizardFieldset sectionTitle={t("sections.select_person")}>
                  <InputAutocompleteGraphQL
                    required
                    label={t("search_people")}
                    query={AgencyHumanAutocomplete}
                    valuesFromResponse={valuesFromResponse(childAgencyHumanId)}
                    onChange={agencyHuman =>
                      /** Clear out any stored relationship information when agency human selected
                       * is changed. New relationship details should be fetched
                       */
                      setFormState({
                        ...formState,
                        agencyHuman,
                        ...clearedRelationships(),
                      })
                    }
                    value={formState.agencyHuman}
                  />
                </WizardFieldset>
              </If>
              <If
                condition={newAgencyHumanSelected || editingExistingAgencyHuman}
              >
                <WizardFieldset
                  sectionTitle={t("sections.personal_info")}
                  collapsible
                >
                  <PersonalInformationSection
                    keystoneAgencyHumanId={childAgencyHumanId}
                    editingExistingAgencyHuman={editingExistingAgencyHuman}
                    ethnicitiesValid={ethnicitiesValid}
                    setEthnicitiesValid={setEthnicitiesValid}
                    formState={formState}
                    setFormState={setFormState}
                    setFormAttribute={setFormAttribute}
                  />
                </WizardFieldset>
                <WizardFieldset
                  sectionTitle={t("sections.ethnicities_and_icwa")}
                  collapsible
                >
                  <EthnicityAndICWASection
                    ethnicitiesValid={ethnicitiesValid}
                    setEthnicitiesValid={setEthnicitiesValid}
                    formState={formState}
                    setFormAttribute={setFormAttribute}
                    setFormState={setFormState}
                  />
                </WizardFieldset>
                <WizardFieldset
                  sectionTitle={t("sections.addresses")}
                  collapsible
                >
                  <AddressesSection
                    formState={formState}
                    setFormAttribute={setFormAttribute}
                  />
                </WizardFieldset>
                <If condition={!formState.agencyHuman?.tiedToEntity}>
                  <WizardFieldset
                    sectionTitle={t("sections.contact_info")}
                    collapsible
                  >
                    <ContactInformationSection
                      formState={formState}
                      setFormAttribute={setFormAttribute}
                    />
                  </WizardFieldset>
                </If>
                <WizardFieldset
                  sectionTitle={t("sections.social_media")}
                  collapsible
                >
                  <SocialMediaSection
                    formState={formState}
                    setFormAttribute={setFormAttribute}
                  />
                </WizardFieldset>
              </If>
              <If condition={existingAgencyHumanWithoutEditing}>
                <AgencyHumanSummary agencyHuman={formState.agencyHuman} />
              </If>
            </WizardPage>
          </If>,
          <If key="relationships_to_child" condition={associatedChildSelected}>
            <WizardPage
              pageTitle={t("pages.relationships_to_children")}
              confirmBeforeCancel
              actionsProps={{
                ...(editingExistingRelationship
                  ? { cancelHref: originPath }
                  : {
                      cancelAction: () => {
                        setFormState({
                          ...formState,
                          /** Clear out any stored relationship information when Back button is clicked.
                           * A confirmation warning modal will appear to prevent users from losing their
                           * unsaved changes without confirming
                           */
                          ...clearedRelationships(),
                        });
                      },
                    }),
                cancelText: t("back"),
                secondaryAction: editingExistingRelationship
                  ? undefined
                  : saveRelationships,
                secondaryDisabled:
                  !existingAgencyHumanSelected ||
                  relationshipsInvalid({
                    relationships: siblings.map(({ id }) => formState[id]),
                  }),
                primaryAction: async () => {
                  await saveRelationships();
                  window.location = originPath;
                },
                primaryDisabled:
                  !existingAgencyHumanSelected ||
                  relationshipsInvalid({
                    relationships: siblings.map(({ id }) => formState[id]),
                  }),
                primaryText: t("save"),
                primarySubmittingText: t("saving"),
              }}
              progress={0}
            >
              <If condition={existingAgencyHumanSelected}>
                {siblings.map(({ id, fullName }, index) => (
                  <WizardFieldset
                    key={`relationship_to_${fullName}`}
                    loading={relationshipsLoading}
                    sectionTitle={t("sections.relationship_to_child", {
                      name: formState.agencyHuman.fullName,
                      childName: fullName,
                    })}
                    secondary={
                      <CopyRelationshipDetailsButton
                        copyToAgencyHumanId={id}
                        copyFromAgencyHumanId={childAgencyHumanId}
                        copyFromName={childName}
                        formState={formState}
                        defaultRelationshipState={{
                          nonKeystoneAgencyHumanId: id,
                        }}
                        setFormAttribute={setFormAttribute(id)}
                      />
                    }
                    collapsible
                    defaultCollapsed={index > 0}
                  >
                    <RelationshipToChildSection
                      relationship={
                        formState[id] || { nonKeystoneAgencyHumanId: id }
                      }
                      setFormAttribute={setFormAttribute(id)}
                    />
                  </WizardFieldset>
                ))}
              </If>
            </WizardPage>
          </If>,
        ].filter(Boolean)}
      />
    </LoadingOverlay>
  );
};
AgencyHumanForm.propTypes = {
  /** The id of the agency to associate with this AgencyHuman */
  agencyId: PropTypes.number,
  /** The agency human ID, if editing an existing agency human */
  agencyHumanId: PropTypes.string,
  /** The name of the agency human, if editing an existing agency human */
  agencyHumanName: PropTypes.string,
  /** Where should the Wizard's cancel and final submit buttons redirect */
  originPath: PropTypes.string.isRequired,
  /** The agency human ID for the child, if creating a connection off of a child's page */
  childAgencyHumanId: PropTypes.string,
  /** The name of the child, if creating a connection off of a child's page */
  childName: PropTypes.string,
};

export default AgencyHumanForm;
