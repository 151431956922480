import PropTypes from "prop-types";

import styles from "./BintiAssistNotice.module.scss";
import aiIcon from "./binti-assist-icon.svg";

/**
 * Icon for Binti Assist/AI/Binti Blue.
 */
const BintiAssistIcon = ({ alt }) => (
  <img
    src={aiIcon}
    aria-hidden="true"
    alt={alt}
    className={styles.bintiAssistIcon}
    data-heart-component="BintiAssistIcon"
  />
);

BintiAssistIcon.propTypes = {
  /**
   * The alt text for the icon.
   */
  alt: PropTypes.string.isRequired,
};

export default BintiAssistIcon;
