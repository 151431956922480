import { MockedProvider } from "@apollo/client/testing";
import { Surface } from "@heart/components";
import { render, screen } from "@testing-library/react";
import React from "react";
import { act } from "react-dom/test-utils";
import { preventionAgencyServiceReferralPath } from "routes";

import { translationWithRoot } from "@components/T";

import AgencyHumanRequirementReconciliationQuery from "@graphql/queries/prevention/AgencyHumanRequirementReconciliation.graphql";
import AgencyServiceReferralRequirementReconciliationQuery from "@graphql/queries/prevention/AgencyServiceReferralRequirementReconciliation.graphql";

import DocumentsAndAttachmentsTab from "./DocumentsAndAttachmentsTab";

const { t } = translationWithRoot(
  "prevention.profile_page.documents_and_attachments_tab"
);

const mocks = [
  {
    request: {
      query: AgencyHumanRequirementReconciliationQuery,
      variables: {
        requirementHolderId: 1,
        first: 20,
      },
    },
    result: {
      data: {
        agencyHumanRequirementReconciliation: {
          pageInfo: {
            endCursor: "abc123",
            startCursor: "xyz789",
            hasNextPage: false,
            hasPreviousPage: false,
          },
          nodes: [
            {
              id: "1",
              isFulfilled: true,
              permissions: ["mayShow"],
              requirement: {
                __typename: "UploadTypeRequirement",
                createdAt: "2023-10-10T10:00:00Z",
                agencyServiceReferral: {
                  id: "2",
                  agencyService: {
                    name: "Test Service",
                  },
                },
              },
              records: [
                {
                  __typename: "UploadedRecord",
                  uploadedRecordFiles: [
                    {
                      id: "file1",
                      filename: "test-document.pdf",
                      downloadUrl:
                        "https://binti.com/download/test-document.pdf",
                    },
                  ],
                },
              ],
            },
          ],
        },
      },
    },
  },
];

describe("DocumentsAndAttachmentsTab", () => {
  it("does not render the title when hideTitle is true", async () => {
    await act(async () => {
      render(
        <MockedProvider mocks={mocks} addTypename={false}>
          <Surface title={"Surface Title"}>
            <DocumentsAndAttachmentsTab
              requirementHolderId={1}
              requirementHolderType={"AgencyHuman"}
              hideTitle={true}
            />
          </Surface>
        </MockedProvider>
      );
    });

    // The title should not be rendered
    expect(
      screen.queryByText(t("documents_and_attachments_table"))
    ).not.toBeInTheDocument();
  });

  it("renders the title when hideTitle is false", async () => {
    render(
      <MockedProvider mocks={mocks} addTypename={false}>
        <DocumentsAndAttachmentsTab
          requirementHolderId={1}
          requirementHolderType="AgencyHuman"
          hideTitle={false}
        />
      </MockedProvider>
    );

    // The title should be rendered
    expect(
      await screen.findByText(t("documents_and_attachments_table"))
    ).toBeInTheDocument();
  });

  it("renders the table with data", async () => {
    render(
      <MockedProvider mocks={mocks} addTypename={false}>
        <DocumentsAndAttachmentsTab
          requirementHolderId={1}
          requirementHolderType={"AgencyHuman"}
        />
      </MockedProvider>
    );

    // Check that the columns render correctly
    expect(await screen.findByText("Document")).toBeInTheDocument();
    expect(await screen.findByText("Date Added")).toBeInTheDocument();
    expect(
      await screen.findByText("Associated Service Referral")
    ).toBeInTheDocument();

    // Check that row data renders correctly
    expect(await screen.findByText("test-document.pdf")).toBeInTheDocument();
    expect(await screen.findByText("10/10/2023")).toBeInTheDocument();
    expect(await screen.findByText("Test Service")).toBeInTheDocument();

    // Check for download link with correct URL
    const downloadLink = await screen.findByRole("link", {
      name: "test-document.pdf",
    });
    expect(downloadLink).toBeInTheDocument();
    expect(downloadLink).toHaveAttribute(
      "href",
      "https://binti.com/download/test-document.pdf"
    );
  });

  it("shows 'Not Available' when the associated service referral is missing", async () => {
    const mocksNoServiceReferral = [
      {
        request: {
          query: AgencyHumanRequirementReconciliationQuery,
          variables: {
            requirementHolderId: 1,
            first: 20,
          },
        },
        result: {
          data: {
            agencyHumanRequirementReconciliation: {
              pageInfo: {
                endCursor: "abc123",
                startCursor: "xyz789",
                hasNextPage: false,
                hasPreviousPage: false,
              },
              nodes: [
                {
                  id: "2",
                  isFulfilled: true,
                  permissions: ["mayShow"],
                  requirement: {
                    __typename: "UploadTypeRequirement",
                    createdAt: "2023-10-11T10:00:00Z",
                    agencyServiceReferral: null,
                  },
                  records: [
                    {
                      __typename: "UploadedRecord",
                      uploadedRecordFiles: [
                        {
                          id: "file1",
                          filename: "another-document.pdf",
                          downloadUrl:
                            "https://binti.com/download/another-document.pdf",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          },
        },
      },
    ];

    render(
      <MockedProvider mocks={mocksNoServiceReferral} addTypename={false}>
        <DocumentsAndAttachmentsTab
          requirementHolderId={1}
          requirementHolderType={"AgencyHuman"}
        />
      </MockedProvider>
    );

    expect(await screen.findByText("another-document.pdf")).toBeInTheDocument();
    expect(await screen.findByText("10/11/2023")).toBeInTheDocument();
    expect(await screen.findByText(t("not_applicable"))).toBeInTheDocument();

    // Check for download link with correct URL
    const downloadLink = await screen.findByRole("link", {
      name: "another-document.pdf",
    });
    expect(downloadLink).toBeInTheDocument();
    expect(downloadLink).toHaveAttribute(
      "href",
      "https://binti.com/download/another-document.pdf"
    );
  });

  it("renders a link when the agency service referral ID is present", async () => {
    const mocksWithServiceReferral = [
      {
        request: {
          query: AgencyHumanRequirementReconciliationQuery,
          variables: {
            requirementHolderId: 1,
            first: 20,
          },
        },
        result: {
          data: {
            agencyHumanRequirementReconciliation: {
              pageInfo: {
                endCursor: "abc123",
                startCursor: "xyz789",
                hasNextPage: false,
                hasPreviousPage: false,
              },
              nodes: [
                {
                  id: "1",
                  isFulfilled: true,
                  permissions: ["mayShow"],
                  requirement: {
                    __typename: "UploadTypeRequirement",
                    createdAt: "2023-10-10T10:00:00Z",
                    agencyServiceReferral: {
                      id: "12345",
                      agencyService: {
                        name: "Test Service",
                      },
                    },
                  },
                  records: [
                    {
                      __typename: "UploadedRecord",
                      uploadedRecordFiles: [
                        {
                          id: "file1",
                          filename: "test-document.pdf",
                          downloadUrl:
                            "https://binti.com/download/test-document.pdf",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          },
        },
      },
    ];

    render(
      <MockedProvider mocks={mocksWithServiceReferral} addTypename={false}>
        <DocumentsAndAttachmentsTab
          requirementHolderId={1}
          requirementHolderType={"AgencyHuman"}
        />
      </MockedProvider>
    );

    // Verify that the link is present with the correct href
    const link = await screen.findByRole("link", { name: "Test Service" });
    expect(link).toBeInTheDocument();
    expect(link).toHaveAttribute(
      "href",
      preventionAgencyServiceReferralPath("12345")
    );

    // Check for download link with correct URL
    const downloadLink = await screen.findByRole("link", {
      name: "test-document.pdf",
    });
    expect(downloadLink).toBeInTheDocument();
    expect(downloadLink).toHaveAttribute(
      "href",
      "https://binti.com/download/test-document.pdf"
    );
  });

  it("renders Associated Child column when requirementHolderType is AgencyServiceReferral", async () => {
    const agencyServiceReferralMocks = [
      {
        request: {
          query: AgencyServiceReferralRequirementReconciliationQuery,
          variables: {
            requirementHolderId: 1,
            first: 20,
          },
        },
        result: {
          data: {
            agencyServiceReferralRequirementReconciliation: {
              pageInfo: {
                endCursor: "abc123",
                startCursor: "xyz789",
                hasNextPage: false,
                hasPreviousPage: false,
              },
              nodes: [
                {
                  id: "1",
                  isFulfilled: true,
                  permissions: ["mayShow"],
                  requirement: {
                    __typename: "UploadTypeRequirement",
                    createdAt: "2023-10-10T10:00:00Z",
                    child: {
                      fullName: "Test Child",
                    },
                  },
                  records: [
                    {
                      __typename: "UploadedRecord",
                      uploadedRecordFiles: [
                        {
                          id: "file1",
                          filename: "child-document.pdf",
                          downloadUrl:
                            "https://binti.com/download/child-document.pdf",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          },
        },
      },
    ];

    render(
      <MockedProvider mocks={agencyServiceReferralMocks} addTypename={false}>
        <DocumentsAndAttachmentsTab
          requirementHolderId={1}
          requirementHolderType={"AgencyServiceReferral"}
        />
      </MockedProvider>
    );

    // Verify the column header
    expect(await screen.findByText("Associated Child")).toBeInTheDocument();

    // Verify the child's name in the table
    expect(await screen.findByText("Test Child")).toBeInTheDocument();

    // Check for download link with correct URL
    const downloadLink = await screen.findByRole("link", {
      name: "child-document.pdf",
    });
    expect(downloadLink).toBeInTheDocument();
    expect(downloadLink).toHaveAttribute(
      "href",
      "https://binti.com/download/child-document.pdf"
    );
  });

  it("shows '--' when no child name is provided", async () => {
    const agencyServiceReferralMocksNoChild = [
      {
        request: {
          query: AgencyServiceReferralRequirementReconciliationQuery,
          variables: {
            requirementHolderId: 1,
            first: 20,
          },
        },
        result: {
          data: {
            agencyServiceReferralRequirementReconciliation: {
              pageInfo: {
                endCursor: "abc123",
                startCursor: "xyz789",
                hasNextPage: false,
                hasPreviousPage: false,
              },
              nodes: [
                {
                  id: "1",
                  isFulfilled: true,
                  permissions: ["mayShow"],
                  requirement: {
                    __typename: "UploadTypeRequirement",
                    createdAt: "2023-10-10T10:00:00Z",
                    child: null,
                  },
                  records: [
                    {
                      __typename: "UploadedRecord",
                      uploadedRecordFiles: [
                        {
                          id: "file1",
                          filename: "child-document.pdf",
                          downloadUrl:
                            "https://binti.com/download/child-document.pdf",
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          },
        },
      },
    ];

    render(
      <MockedProvider
        mocks={agencyServiceReferralMocksNoChild}
        addTypename={false}
      >
        <DocumentsAndAttachmentsTab
          requirementHolderId={1}
          requirementHolderType={"AgencyServiceReferral"}
        />
      </MockedProvider>
    );

    expect(await screen.findByText("--")).toBeInTheDocument();
  });
});
