import { Layout } from "@heart/components";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";
import ChildBreadcrumbs from "@components/child/ChildBreadcrumbs";
import ChildSidebar from "@components/child/ChildSidebar";

import UpdateLevelOfCare from "./UpdateLevelOfCare";

const { t } = translationWithRoot("level_of_care_assignment");

/** Full page layout for updating a child's level of care */
const UpdateLevelOfCareLayout = ({
  child,
  childAgencyHumanId,
  childPermissions,
  levelOfCareAssignmentId,
  onSuccessRedirectTo,
  viewMatchingFamiliesPath,
}) => {
  const { id, fullName, firstName, lastName } = child;

  return (
    <Layout
      pageTitle={t("page_title", { name: fullName })}
      breadcrumbs={<ChildBreadcrumbs childId={id} childName={fullName} />}
      sidebar={{
        fullHeight: true,
        opaque: true,
        title: fullName,
        collapsibleOnDesktop: true,
        content: (
          <ChildSidebar
            currentPage="update_level_of_care"
            avatar={{
              firstName,
              lastName,
            }}
            label={fullName}
            agencyHumanId={childAgencyHumanId}
            childId={id}
            childFullName={fullName}
            childPermissions={childPermissions}
            viewMatchingFamiliesPath={viewMatchingFamiliesPath}
          />
        ),
      }}
      main={{
        content: (
          <UpdateLevelOfCare
            childId={id}
            levelOfCareAssignmentId={levelOfCareAssignmentId}
            onSuccessRedirectTo={onSuccessRedirectTo}
          />
        ),
      }}
    />
  );
};
UpdateLevelOfCareLayout.propTypes = {
  child: PropTypes.shape({
    id: PropTypes.number.isRequired,
    fullName: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  childAgencyHumanId: PropTypes.number.isRequired,
  childPermissions: PropTypes.shape({
    mayViewMatchingFamilies: PropTypes.bool,
    mayViewPhotos: PropTypes.bool,
    mayViewRequirements: PropTypes.bool,
  }).isRequired,
  levelOfCareAssignmentId: PropTypes.number,
  onSuccessRedirectTo: PropTypes.string,
  viewMatchingFamiliesPath: PropTypes.string,
};

export default UpdateLevelOfCareLayout;
