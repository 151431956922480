import { Layout } from "@heart/components";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";
import ChildBreadcrumbs from "@components/child/ChildBreadcrumbs";
import ChildSidebar from "@components/child/ChildSidebar";

import ProgramsForm from "./ProgramsForm";

const { t } = translationWithRoot("program_assignments");

/** Full page layout for updating a child's programs */
const UpdatePrograms = ({
  child,
  childAgencyHumanId,
  availablePrograms,
  currentProgramAssignments,
  onEditRedirectTo,
  onSuccessRedirectTo,
  editable,
  mayUpdatePrograms = true,
  childPermissions,
  viewMatchingFamiliesPath,
}) => {
  const { id, fullName, firstName, lastName } = child;

  return (
    <Layout
      pageTitle={t("page_title", { name: fullName })}
      breadcrumbs={<ChildBreadcrumbs childId={id} childName={fullName} />}
      sidebar={{
        fullHeight: true,
        opaque: true,
        title: fullName,
        collapsibleOnDesktop: true,
        content: (
          <ChildSidebar
            currentPage="update_level_of_care"
            avatar={{
              firstName,
              lastName,
            }}
            label={fullName}
            childId={id}
            childFullName={fullName}
            agencyHumanId={childAgencyHumanId}
            childPermissions={childPermissions}
            viewMatchingFamiliesPath={viewMatchingFamiliesPath}
          />
        ),
      }}
      main={{
        content: (
          <ProgramsForm
            childId={id}
            availablePrograms={availablePrograms}
            currentProgramAssignments={currentProgramAssignments}
            onEditRedirectTo={onEditRedirectTo}
            onSuccessRedirectTo={onSuccessRedirectTo}
            editable={editable}
            mayUpdatePrograms={mayUpdatePrograms}
          />
        ),
      }}
    />
  );
};
UpdatePrograms.propTypes = {
  child: PropTypes.shape({
    id: PropTypes.number.isRequired,
    fullName: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  childAgencyHumanId: PropTypes.number.isRequired,
  availablePrograms: PropTypes.arrayOf(PropTypes.array).isRequired,
  currentProgramAssignments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      program_id: PropTypes.number,
      start_date: PropTypes.string,
      end_date: PropTypes.string,
    })
  ).isRequired,
  onEditRedirectTo: PropTypes.string,
  onSuccessRedirectTo: PropTypes.string,
  editable: PropTypes.bool.isRequired,
  mayUpdatePrograms: PropTypes.bool,
  childPermissions: PropTypes.shape({
    mayViewMatchingFamilies: PropTypes.bool,
    mayViewPhotos: PropTypes.bool,
    mayViewRequirements: PropTypes.bool,
  }).isRequired,
  levelOfCareAssignmentId: PropTypes.number,
  viewMatchingFamiliesPath: PropTypes.string,
};

export default UpdatePrograms;
