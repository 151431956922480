import { Layout } from "@heart/components";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";
import ChildBreadcrumbs from "@components/child/ChildBreadcrumbs";
import ChildSidebar from "@components/child/ChildSidebar";

import PhotoUploader from "./PhotoUploader";

const { t } = translationWithRoot("photos");

/** Full page layout for photos of child in care */
const Photos = ({
  photos,
  child,
  childAgencyHumanId,
  childPermissions,
  canUploadChildPhoto,
  canDeleteChildPhoto,
  viewMatchingFamiliesPath,
}) => {
  const { id, fullName, firstName, lastName } = child;

  return (
    <Layout
      pageTitle={t("page_title", { name: fullName })}
      breadcrumbs={<ChildBreadcrumbs childId={id} childName={fullName} />}
      sidebar={{
        fullHeight: true,
        opaque: true,
        title: fullName,
        collapsibleOnDesktop: true,
        content: (
          <ChildSidebar
            currentPage="photos"
            avatar={{
              firstName,
              lastName,
            }}
            label={fullName}
            agencyHumanId={childAgencyHumanId}
            childId={id}
            childFullName={fullName}
            childPermissions={childPermissions}
            viewMatchingFamiliesPath={viewMatchingFamiliesPath}
          />
        ),
      }}
      main={{
        content: (
          <PhotoUploader
            photos={photos}
            childId={id}
            canUploadChildPhoto={canUploadChildPhoto}
            canDeleteChildPhoto={canDeleteChildPhoto}
          />
        ),
      }}
    />
  );
};
Photos.propTypes = {
  photos: PropTypes.array.isRequired,
  child: PropTypes.shape({
    id: PropTypes.number.isRequired,
    fullName: PropTypes.string.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  childAgencyHumanId: PropTypes.number.isRequired,
  childPermissions: PropTypes.shape({
    mayViewFamilyFindingSearches: PropTypes.bool,
    mayViewMatchingFamilies: PropTypes.bool,
    mayViewPhotos: PropTypes.bool,
    mayViewRequirements: PropTypes.bool,
  }).isRequired,
  canUploadChildPhoto: PropTypes.bool.isRequired,
  canDeleteChildPhoto: PropTypes.bool.isRequired,
  viewMatchingFamiliesPath: PropTypes.string,
};

export default Photos;
