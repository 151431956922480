import { InputDate, InputDropdown } from "@heart/components";
import classnames from "classnames";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { Component } from "react";

import { translationWithRoot } from "@components/T";

import { generateIds } from "@lib/generateId";

import Notes from "./Notes";

const { t } = translationWithRoot("background_checks.bg_check_log_row");

/** A row in a household's BG check logs: Applicant Data > BG Checks */
class BGCheckLogRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ids: generateIds("notesId")(),
    };
  }

  createOrUpdateLog = log => {
    if (log.updating) {
      return false;
    }

    if (log.id) {
      return this.props.updateLog(log);
    }

    return this.props.createLog(log);
  };

  dateBlurred = selectedDate => {
    const { log } = this.props;

    if (log.completed_at !== selectedDate && selectedDate !== "Invalid date") {
      this.createOrUpdateLog({ ...log, completed_at: selectedDate });
    }
  };

  statusChanged = selectedStatus => {
    const { log } = this.props;

    this.createOrUpdateLog({ ...log, status: selectedStatus });
  };

  loggedByChanged = selectedLoggedBy => {
    const { log } = this.props;

    this.createOrUpdateLog({ ...log, logged_by_id: selectedLoggedBy });
  };

  render() {
    const { log, statusOptions, loggedByOptions } = this.props;

    const rowClassName = classnames({
      "background-loading": log.updating,
    });

    const statusSelectOptions = statusOptions.map(
      opt => [
        I18n.t(`activerecord.attributes.background_check_log/statuses.${opt}`),
        opt,
      ],
      {}
    );

    return (
      <tr
        className={rowClassName}
        data-testupdating={Boolean(log.updating).toString()}
      >
        <td key={`adult_name-${log.key}`}>{log.adult_name}</td>
        <td key={`role-${log.key}`}>
          {I18n.t(`activerecord.attributes.user/roles.${log.adult_role}`)}
        </td>
        <td key={`background_check-${log.key}`}>{log.background_check}</td>
        <td key={`logged_by-${log.key}`}>
          {isEmpty(loggedByOptions) ? (
            log.logged_by
          ) : (
            <InputDropdown
              hideLabel
              label={t("logged_by_label", {
                background_check_title: log.background_check,
              })}
              hideBlank
              name="logged-by-select"
              onChange={this.loggedByChanged}
              value={`${log.logged_by_id}`}
              values={loggedByOptions}
            />
          )}
        </td>
        <td key={`status-${log.key}`}>
          <InputDropdown
            hideBlank
            hideLabel
            label={t("status_label", {
              background_check_title: log.background_check,
            })}
            name="status-select"
            onChange={this.statusChanged}
            value={log.status}
            disabled={!log.current_user_can_edit}
            values={statusSelectOptions}
          />
        </td>
        <td key={`date-${log.key}`} className="min-required-width">
          <InputDate
            onBlur={this.dateBlurred}
            required
            disabled={
              !log.current_user_can_edit || log.status === "not_applicable"
            }
            label={t("completed_at_label", {
              background_check_title: log.background_check,
            })}
            hideLabel
            value={log.completed_at || ""}
          />
        </td>
        <td key={`notes-${log.key}`}>
          <Notes
            log={log}
            createOrUpdateLog={this.createOrUpdateLog}
            disabled={!log.current_user_can_edit}
          />
        </td>
      </tr>
    );
  }
}

BGCheckLogRow.propTypes = {
  log: PropTypes.object.isRequired,
  statusOptions: PropTypes.array.isRequired,
  createLog: PropTypes.func.isRequired,
  updateLog: PropTypes.func.isRequired,
  loggedByOptions: PropTypes.any,
};

export default BGCheckLogRow;
