import { InputHidden } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

import AfcarsRequired from "@components/ccwis_demo/AfcarsRequired";
import NestedMultiFormInput from "@components/inputs/nested_multi/NestedMultiFormInput";

/** ### Usage
 *
 * A component for collecting how a person identifies their ethnicities
 */
const EthnicityWrapper = ({
  selectedEthnicities,
  inputName,
  labelText,
  isRequired,
  isAfcarsRequired,
  ethnicityOptions,
  description = "",
}) => {
  const [ethnicities, setEthnicities] = useState(selectedEthnicities);

  return (
    <div>
      <NestedMultiFormInput
        options={ethnicityOptions}
        label={
          <Fragment>
            {labelText}
            <If condition={isRequired}>*</If>
            <If condition={isAfcarsRequired}>
              {" "}
              <AfcarsRequired />
            </If>
          </Fragment>
        }
        description={description}
        selectedOptions={ethnicities}
        onSelectedOptionsChange={newEthnicities => {
          setEthnicities(newEthnicities);
        }}
      />
      <InputHidden name={inputName} value={JSON.stringify(ethnicities)} />
    </div>
  );
};

EthnicityWrapper.propTypes = {
  selectedEthnicities: PropTypes.object.isRequired,
  ethnicityOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  inputName: PropTypes.string.isRequired,
  // The full label requires some decorators for requiredness and AFCARS requiredness
  // so pass only the translated text in here.
  labelText: PropTypes.node.isRequired,
  isRequired: PropTypes.bool,
  isAfcarsRequired: PropTypes.bool,
  description: PropTypes.node,
};

export default EthnicityWrapper;
