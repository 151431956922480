import { useQuery } from "@apollo/client";
import {
  Button,
  Flex,
  LoadingOverlay,
  Surface,
  Text,
  useConfirmModal,
} from "@heart/components";
import * as HeartTable from "@heart/components/table/Table";
import PropTypes from "prop-types";
import { childRelationshipsDashboardPath } from "routes";

import { translationWithRoot } from "@components/T";

import AgencyHumansByRelationshipIdsQuery from "@graphql/queries/AgencyHumansByRelationshipIds.graphql";

import {
  getEncodedBase64SearchParams,
  setBase64SearchParams,
} from "@lib/base64SearchParams";
import useBase64SearchParam from "@lib/react-use/useBase64SearchParam";

import { B64PARAMS } from "@root/constants";

import BulkOutreachCampaignRecipientRow from "./BulkOutreachCampaignRecipientRow";

const { Caption, Table, Thead, Tr, Th, Tbody } = HeartTable;
const { t } = translationWithRoot(
  "family_finding.bulk_outreach_campaigns.sidebar"
);

/**
 * BulkOutreachCampaignSidebar component
 */
const BulkOutreachCampaignSidebar = ({
  childId,
  childAgencyHumanId,
  channel,
  showChangeRecipientsButton,
  allowRemoveRecipients,
}) => {
  const { ConfirmModal, confirm } = useConfirmModal();

  const relationshipIds = useBase64SearchParam("selectedIds") || [];
  const { data = { agencyHumansByRelationshipIds: [] }, loading } = useQuery(
    AgencyHumansByRelationshipIdsQuery,
    {
      variables: {
        keystoneAgencyHumanId: childAgencyHumanId,
        relationshipIds: relationshipIds,
      },
    }
  );

  return (
    <Flex column gap="300">
      <Text textColor="emphasis-500" textStyle="emphasis-100">
        {allowRemoveRecipients ? t("step_one") : t("recipients")}
      </Text>
      <Surface title={t("recipients")} hideTitle>
        <LoadingOverlay active={loading}>
          <Table>
            <Caption>
              <Flex justify="space-between">
                {t("recipients_with_count", { count: relationshipIds.length })}
                <If condition={showChangeRecipientsButton}>
                  <ConfirmModal submitText={t("confirm")} submitDangerButton>
                    {t("confirm_change_recipient")}
                  </ConfirmModal>
                  <Button
                    variant="secondary"
                    onClick={() =>
                      confirm().then(isConfirmed => {
                        if (isConfirmed)
                          window.location = `${childRelationshipsDashboardPath({
                            id: childId,
                          })}?${B64PARAMS}=${getEncodedBase64SearchParams()}`;
                      })
                    }
                  >
                    {t("change_recipients")}
                  </Button>
                </If>
              </Flex>
            </Caption>
            <Thead>
              <Tr>
                <Th>{t("name")}</Th>
                <Th>{t(`${channel}.address`)}</Th>
                <If condition={allowRemoveRecipients}>
                  <Th>{t("actions")}</Th>
                </If>
              </Tr>
            </Thead>
            <Tbody>
              {data.agencyHumansByRelationshipIds.map(
                ({ agencyHuman, relationshipId }) => (
                  <BulkOutreachCampaignRecipientRow
                    key={agencyHuman.id}
                    agencyHuman={agencyHuman}
                    relationshipId={relationshipId}
                    allowRemoveRecipients={allowRemoveRecipients}
                    relationshipIds={relationshipIds}
                    channel={channel}
                    setBase64SearchParams={setBase64SearchParams}
                  />
                )
              )}
            </Tbody>
          </Table>
        </LoadingOverlay>
      </Surface>
    </Flex>
  );
};

BulkOutreachCampaignSidebar.propTypes = {
  childId: PropTypes.number.isRequired,
  childAgencyHumanId: PropTypes.number.isRequired,
  channel: PropTypes.string.isRequired,
  /** Whether or not to show the button to change recipients of a bulk outreach campaign */
  showChangeRecipientsButton: PropTypes.bool.isRequired,
  /** Whether or not to allow removing recipients */
  allowRemoveRecipients: PropTypes.bool.isRequired,
};

export default BulkOutreachCampaignSidebar;
