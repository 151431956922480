/* rails-erb-loader-dependencies ../config/locales/ */
import I18n from "i18n-js";
import { l, st } from "I18nHelpers";

I18n.translations = {};




import en from "./i18n/en";
I18n.translations["en"] = en;

import es from "./i18n/es";
I18n.translations["es"] = es;

import bn from "./i18n/bn";
I18n.translations["bn"] = bn;

import de from "./i18n/de";
I18n.translations["de"] = de;

import el from "./i18n/el";
I18n.translations["el"] = el;

import fr from "./i18n/fr";
I18n.translations["fr"] = fr;

import frCA from "./i18n/fr-CA";
I18n.translations["fr-CA"] = frCA;

import gu from "./i18n/gu";
I18n.translations["gu"] = gu;

import hr from "./i18n/hr";
I18n.translations["hr"] = hr;

import ht from "./i18n/ht";
I18n.translations["ht"] = ht;

import hy from "./i18n/hy";
I18n.translations["hy"] = hy;

import it from "./i18n/it";
I18n.translations["it"] = it;

import ko from "./i18n/ko";
I18n.translations["ko"] = ko;

import pl from "./i18n/pl";
I18n.translations["pl"] = pl;

import pt from "./i18n/pt";
I18n.translations["pt"] = pt;

import ptBR from "./i18n/pt-BR";
I18n.translations["pt-BR"] = ptBR;

import ru from "./i18n/ru";
I18n.translations["ru"] = ru;

import so from "./i18n/so";
I18n.translations["so"] = so;

import tl from "./i18n/tl";
I18n.translations["tl"] = tl;

import uk from "./i18n/uk";
I18n.translations["uk"] = uk;

import vi from "./i18n/vi";
I18n.translations["vi"] = vi;

import zh from "./i18n/zh";
I18n.translations["zh"] = zh;


// Make I18n.l behave like it does in Ruby for single argument calls - it should
// assume the value is a date and format it with our default.
I18n.l = l;

I18n.st = st;

export default I18n;
