/* eslint-disable import/prefer-default-export */
import { useQuery } from "@apollo/client";
import { flatten, upperCase, values } from "lodash";
import { useEffect } from "react";

import { isTestEnvironment } from "@lib/environment";

import { GRAPHQL_PERMISSIONS, GRAPHQL_POSSIBLE_TYPES } from "@root/constants";

import Poller from "../clients/Poller";

let flattenedPossibleTypes;

/**
 * Checks if a given typename is a valid GraphQL typename in our Schema.
 *
 * @param typename A GraphQL typename
 * @returns true if the typename is valid, false otherwise
 */
export const isValidTypename = typename => {
  /** Our GRAPHQL_POSSIBLE_TYPES are dynamically generated in an erb file
   * that our Jest tests don't evaluate. Since we're creeating our own fake
   * data there anyway, checking for valid typenames isn't valuable, so we
   * always return true
   */
  if (isTestEnvironment()) return true;

  flattenedPossibleTypes =
    flattenedPossibleTypes || flatten(values(GRAPHQL_POSSIBLE_TYPES));

  if (!flattenedPossibleTypes.includes(typename)) {
    // eslint-disable-next-line no-console
    console.warn(`${typename} is not a valid GraphQL type`);

    return false;
  }

  return true;
};

/**
 * If given a single argument of a typename, returns a function taking an
 * object. That function returns true if the object matches the typename.
 *
 * If given both a typename and an object, returns true if the object matches
 * the typename.
 */
export const typeEq = (typename, object) => {
  if (!isValidTypename(typename)) {
    return false;
  }

  if (typename && object) {
    return object.__typename === typename;
  }

  return obj => obj.__typename === typename;
};

/**
 * Helper to provide options for <InputFilterable> components from GraphQL enum types
 * with translated labels.
 *
 * You probably don't want to use this directly. See `selectEnumProps` instead.
 *
 * @param {enumType} A GraphQL enum type. Should be an object with a key
 * `enumValues` that is an array of objects with the key `name`. Required.
 * @param {i18nPrefix} The prefix of the enum within the i18n translations,
 * if available. This prefix is relative to "activerecord.enums", where all
 * the enum translations should be. For example, if you have an enum key,
 * "other", and i18nPrefix, "my_object.my_enum", the full key for the label
 * used will be `activerecord.enums.my_object.my_enum.other". Optional.
 */
export const enumToSelectOptions = ({ enumType, i18nPrefix }) =>
  enumType.enumValues.reduce((acc, { name }) => {
    const label = i18nPrefix
      ? I18n.t(`activerecord.enums.${i18nPrefix}.${name}`)
      : name;
    return acc.concat([{ label, value: name }]);
  }, []);

/**
 * Helper to provide option and value props for <InputFilterable> components from
 * GraphQL enum types with translated labels.
 *
 * Example usage:
 *
 *  <InputFilterable
 *    otherProp="some value"
 *    {...selectEnumProps({
 *      value,
 *      enumType: MyEnumValues,
 *      i18nPrefix: "my_object.my_enum"
 *    })}
 *  />
 *
 * @param {enumType} A GraphQL enum type. Should be an object with a key
 * `enumValues` that is an array of objects with the key `name`. Required.
 * @param {value} A GraphQL enum type. Should be an object with a key
 * `enumValues` that is an array of objects with the key `name`. Required.
 * @param {i18nPrefix} The prefix of the enum within the i18n translations,
 * if available. This prefix is relative to "activerecord.enums", where all
 * the enum translations should be. For example, if you have an enum key,
 * "other", and i18nPrefix, "my_object.my_enum", the full key for the label
 * used will be `activerecord.enums.my_object.my_enum.other". Optional.
 * @param {isMulti} Boolean argument passed through as a prop to the output
 * props hash. If true, the value also returns multiples. Optional.
 */
export const selectEnumProps = ({ value, enumType, i18nPrefix, isMulti }) => {
  const options = enumToSelectOptions({ enumType, i18nPrefix });
  const method = isMulti ? "filter" : "find";

  return {
    options,
    isMulti,
    value: options[method](({ value: optionValue }) => {
      if (Array.isArray(value)) {
        return value.some(arrayValue => arrayValue === optionValue);
      }

      return value === optionValue;
    }),
  };
};

/**
 * A wrapper around apollo's useQuery but with sophisticated polling behavior.
 * The data returned from the query is passed to the isReady() callback and
 * once that callback returns true, the polling ends.
 *
 * @param {query} the query to pass to useQuery
 * @param {isReady} a callback to which we yield data returned from the query.
 *   should return true if the data no longer needs polling.
 * @param  {useQueryArguments} any other arguments to pass to useQuery
 *
 * @returns the return value of useQuery, but loading will be true if either
 * useQuery returned loading true or isReady is false.
 */
export const usePollingQuery = (query, isReady, ...useQueryArguments) => {
  const useQueryReturn = useQuery(query, ...useQueryArguments);
  const { data, refetch } = useQueryReturn;

  const poll = retry => {
    refetch().then(({ data: fetched }) => {
      if (!isReady(fetched)) {
        retry();
      }
    });
  };

  useEffect(() => {
    if (!isReady(data)) {
      Poller.startPolling(poll);
    }
  });

  return {
    ...useQueryReturn,
    loading: useQueryReturn.loading || !isReady(data),
  };
};

/**
 * Given a GraphQL object fetched with its permissions (in the key
 * `permissions`), returns a policy object with methods of the format
 * `may<permission>()`, e.g. `mayShow()`.
 *
 * @param {*} object - the object
 */
export const policy = object =>
  GRAPHQL_PERMISSIONS.reduce(
    (acc, permission) => ({
      ...acc,
      [permission]: () => object.permissions.includes(permission),
    }),
    {}
  );

/**
 * Converts a snake_case argument into a GraphQL enum type
 * (all caps with underscores)
 *
 * Example usage:
 *
 * snakeCaseToEnum("foo_bar_baz")
 *  => "FOO_BAR_BAZ"
 *
 * @param {value} the value in snake_case format
 *
 * @returns the value in snake case
 */
export const snakeCaseToEnum = value => upperCase(value).replace(/ /g, "_");
