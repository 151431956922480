import { useQuery } from "@apollo/client";
import { InputFilterable } from "@heart/components";
import { isEmpty, keyBy, noop } from "lodash";
import PropTypes from "prop-types";
import { useMemo } from "react";

import { translationWithRoot } from "@components/T";

import PrimarySubdivisionsQuery from "@graphql/queries/PrimarySubdivisions.graphql";

const { t } = translationWithRoot("heart.components.inputs.input_address");

const loadingOrError = (loading, error) => {
  if (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return error || loading;
};

/** ### Usage
 *
 * A select component that allows selection of a primary subdivision of passed country.
 * Primarily for use within an address form, but can be used independently if only
 * state level granularity is required.
 *
 */
export const PrimarySubdivisionSelect = ({
  primarySubdivisionCode = "",
  primarySubdivision = "",
  name = "primarySubdivision",
  countryCode,
  usePrimarySubdivisionCodeAsValue = true,
  onChange = noop,
  description = null,
  required = false,
}) => {
  let selectedCode = primarySubdivisionCode;
  let primarySubdivisionName = null;

  const { data, loading, error } = useQuery(PrimarySubdivisionsQuery, {
    variables: { countryCode: countryCode },
    // if countryCode is not set (""), we skip running this query
    skip: !countryCode,
  });

  const codeMap = useMemo(() => {
    if (!countryCode || loadingOrError(loading, error)) return {};
    return keyBy(data.primarySubdivisions, "code");
  }, [countryCode, data, loading, error]);

  const nameMap = useMemo(() => {
    if (!countryCode || loadingOrError(loading, error)) return {};
    return keyBy(data.primarySubdivisions, "name");
  }, [countryCode, data, loading, error]);

  const primarySubdivisionsList =
    !countryCode || loadingOrError(loading, error)
      ? []
      : data.primarySubdivisions;

  // Originally there was just a primarySubdivision prop in the address prop.
  // It was always name. Now you can use code! Which is a bit more consistent with
  // other use cases in our codebase.
  // To maintain backward compatibility, we allow this to be either the code or the name
  // and set the primarySubdivisionCode from this field.
  if (
    !selectedCode &&
    !!primarySubdivision &&
    !isEmpty(primarySubdivisionsList)
  ) {
    selectedCode = (codeMap[primarySubdivision] || nameMap[primarySubdivision])
      ?.code;
  }

  primarySubdivisionName = codeMap[selectedCode]?.name;

  return (
    <InputFilterable
      label={t("primary_subdivision")}
      description={description}
      required={required}
      values={
        primarySubdivisionsList
          ? primarySubdivisionsList.map(({ name: subdivName, code }) => ({
              label: subdivName,
              value: usePrimarySubdivisionCodeAsValue ? code : subdivName,
            }))
          : []
      }
      name={name}
      value={
        primarySubdivisionName
          ? {
              label: primarySubdivisionName,
              value: usePrimarySubdivisionCodeAsValue
                ? selectedCode
                : primarySubdivisionName,
            }
          : []
      }
      onChange={({ value, label }) => {
        onChange({
          name: label,
          code: usePrimarySubdivisionCodeAsValue ? value : nameMap[label].code,
        });
      }}
    />
  );
};

PrimarySubdivisionSelect.propTypes = {
  /** Optionally preselect a specific subdivision by code */
  primarySubdivisionCode: PropTypes.string,

  /* Which country? If not provided it will simply be a blank select. */
  countryCode: PropTypes.string.isRequired,

  /* Optionally name the html field; likely for activeadmin use cases */
  name: PropTypes.string,

  /** The `onChange` function is invoked with the selected code and name
   *  Note that both code and name will be the name if usePrimarySubdivisionCodeAsValue
   * is false
   */
  onChange: PropTypes.func,

  /**
   * NOTE: prefer primarySubdivisionCode; this is provided only for backwards
   * compatibility where callers of InputAddress were passing in primarySubdivision.
   * Optionally preselect a specific subdivision by name OR code. If both this and
   * primarySubdivisionCode are provided and they do not match, code will be used.
   */
  primarySubdivision: PropTypes.string,

  /* Historically this component used the primary subdivision (in US, state) name as both the value
   * and label of the dropdown. Other places in our code use the code as the value and name as the
   * label. This lets us do that. In order to make this the default/only behavior we'd need
   * to do a migration of the values already stored in `AddressLocation`. TODO: ENG-20136
   */
  usePrimarySubdivisionCodeAsValue: PropTypes.bool,

  /** An optional description to display under the label */
  description: PropTypes.string,

  /** Is this field required? By default it is not */
  required: PropTypes.bool,
};

export const { propTypes } = PrimarySubdivisionSelect;

export default PrimarySubdivisionSelect;
