import PropTypes from "prop-types";

import ProgressArc from "../ProgressArc";
import styles from "./Requirement.module.scss";

/**
 * Table cell that displays the status of a requirement as a ProgressArc.
 * Used only for applications, but shared across multiple requirement types
 * and used on both the legacy and requirements documents pages.
 */
const RequirementStatus = ({
  isFulfilled,
  progressPercentage,
  unreviewedPercentage,
}) => (
  <div className={styles.statusCell}>
    <ProgressArc
      progress={progressPercentage}
      progressIncomplete={!isFulfilled}
      secondaryProgress={unreviewedPercentage}
      secondaryProgressTitle={I18n.t(
        "javascript.components.common.form_unreviewed_percentage",
        { percent: unreviewedPercentage }
      )}
    />
  </div>
);

RequirementStatus.propTypes = {
  progressPercentage: PropTypes.number,
  isFulfilled: PropTypes.bool,
  unreviewedPercentage: PropTypes.number,
};

export default RequirementStatus;
