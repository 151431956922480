import { useMutation } from "@apollo/client";
import { UploadButton, Alert } from "@heart/components";
import PropTypes from "prop-types";
import { Fragment, useState } from "react";

import OverrideActionModalLauncher from "@components/application_requirements_shared/OverrideActionModalLauncher";
import RequirementStatus from "@components/requirements/RequirementStatus";
import { Td, Tr } from "@components/reusable_ui/Table";
import useUploadRow from "@components/reusable_ui/useUploadRow";

import CreateApplicationUploadedRecord from "@graphql/mutations/CreateApplicationUploadedRecord.graphql";
import CreateUploadTypeOverride from "@graphql/mutations/CreateUploadTypeOverride.graphql";

import { policy } from "@lib/graphqlHelpers";
import useFeatureFlag from "@lib/useFeatureFlag";
import useShrine from "@lib/useShrine";

import UploadTypeDocuments from "../application_requirements_shared/UploadTypeDocuments";
import UploadTypeRequirementTitle from "../requirements/UploadTypeRequirementTitle";
import styles from "./ApplicationRequirementTable.module.scss";

/**
 * Row for an upload type requirement in the application requirements table
 * on the Caregiver Documents v2 page.
 */
const ApplicationUploadTypeRequirementRow = ({ fulfillment, application }) => {
  const [createUploadedRecord] = useMutation(CreateApplicationUploadedRecord);
  const [createUploadTypeOverride] = useMutation(CreateUploadTypeOverride);
  const [showAlert, setShowAlert] = useState(false);
  const { flag: ffShrineSdv2Uploads042024, loading } = useFeatureFlag(
    "ff_shrine_sdv2_uploads_04_2024"
  );
  const { prepareShrineFiles, ShrineErrorAlert } = useShrine();

  const { requirement } = fulfillment;

  const uploadFiles = async files => {
    if (files.length === 0) {
      setShowAlert(true);

      return;
    }

    let fileVariables;

    if (ffShrineSdv2Uploads042024) {
      fileVariables = {
        files: [],
        shrineFiles: await prepareShrineFiles(files),
      };
    } else {
      fileVariables = { files };
    }

    await createUploadedRecord({
      variables: {
        applicationId: application.id,
        uploadTypeSlug: requirement.uploadType.slug,
        userAgencyProfileId: requirement.userAgencyProfile?.id,
        ...fileVariables,
      },
    });
  };

  const onOverride = ({ reason, details, optionKey, grantDate }) =>
    createUploadTypeOverride({
      variables: {
        holderToken: application.holderToken,
        reason,
        details,
        optionKey,
        grantDate,
        uploadTypeSlug: requirement.uploadType.slug,
        userAgencyProfileId: requirement.userAgencyProfile?.id,
      },
    });

  const replaceOverrideRecord = ({
    reason,
    details,
    optionKey,
    grantDate,
    overrideRecord,
  }) =>
    createUploadTypeOverride({
      variables: {
        holderToken: application.holderToken,
        reason,
        details,
        optionKey,
        grantDate,
        uploadTypeSlug: requirement.uploadType.slug,
        userAgencyProfileId: requirement.userAgencyProfile?.id,
        overrideRecordIdToReplace: overrideRecord.id,
      },
    });

  const { getTrProps, getInputProps, aroundLoader, isLoading } =
    useUploadRow(uploadFiles);

  // avoid rendering until the feature flag is loaded.
  // it can cause a race condition if we try uploading when the feature flag is on,
  // but the flag variable is still undefined while loading.
  if (loading) return null;

  return (
    <Fragment>
      <Tr {...getTrProps()}>
        <Td>
          <UploadTypeRequirementTitle
            {...{ requirement, title: fulfillment.title }}
          />
        </Td>
        <Td>
          <RequirementStatus
            isFulfilled={fulfillment.isFulfilled}
            progressPercentage={fulfillment.progressPercentage}
          />
        </Td>
        <Td>
          <UploadTypeDocuments
            disabled={isLoading}
            showOverrideRecords={true}
            {...{
              holderToken: application.holderToken,
              aroundLoader,
              fulfillment,
              replaceOverrideRecord,
            }}
          />
        </Td>
        <Td>
          <div className={styles.actions}>
            <OverrideActionModalLauncher {...{ fulfillment, onOverride }} />
            {policy(fulfillment).mayCreateUploadedRecord() && (
              <UploadButton passedInputProps={getInputProps} />
            )}
          </div>
        </Td>
      </Tr>

      <ShrineErrorAlert />
      <Alert
        hidden={!showAlert}
        isAlert={true}
        title={I18n.t("javascript.components.common.alert")}
        onSubmit={() => setShowAlert(false)}
        submitText={I18n.t("attachments.errors.ok")}
      >
        {I18n.t("attachments.errors.unpermitted_format")}
      </Alert>
    </Fragment>
  );
};

ApplicationUploadTypeRequirementRow.propTypes = {
  fulfillment: PropTypes.object.isRequired,
  application: PropTypes.object.isRequired,
};

export default ApplicationUploadTypeRequirementRow;
