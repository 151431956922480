import InputAutocompleteGraphQL from "@heart/components/inputs/InputAutocompleteGraphQL";
import I18n from "i18n-js";
import { compact } from "lodash";
import PropTypes from "prop-types";
import { useCallback } from "react";

import ApplicantsByNameAutocomplete from "@graphql/queries/ApplicantsByNameAutocomplete.graphql";

const extractAddressString = ({ address, agencyHumanAddresses = [] }) => {
  for (let i = 0; i < agencyHumanAddresses.length; i += 1) {
    if (agencyHumanAddresses[i].type.includes("physical_home")) {
      const { addressLine1, addressLine2, city } = agencyHumanAddresses[i];
      return compact([addressLine1, addressLine2, city]).join(", ");
    }
  }

  if (address) {
    return compact([address.address_street, address.address_city]).join(", ");
  }

  return null;
};

/**
 * Autocomplete component for selecting a family by name using backend
 * fuzzy search.
 */
const CaregiverAutocomplete = ({ name, required, caregiver, setCaregiver }) => {
  const valuesFromResponse = useCallback(
    ({ searchUserAgencyProfilesByName }) =>
      searchUserAgencyProfilesByName.map(
        ({
          id,
          fullName: uapFullName,
          address,
          agencyHuman,
          relevantApplication,
        }) => {
          const fullName = agencyHuman?.fullName || uapFullName;
          let label = fullName;

          const addressString = extractAddressString({
            address,
            agencyHumanAddresses: agencyHuman?.addresses,
          });

          if (addressString) {
            label = `${fullName} (${addressString})`;
          }

          return {
            value: id,
            label,
            applicationId: relevantApplication?.id,
            userAgencyProfileId: id,
          };
        }
      ),
    []
  );

  const onChange = useCallback(
    ({ value, label, applicationId, userAgencyProfileId }) =>
      setCaregiver({
        value,
        label,
        applicationId,
        userAgencyProfileId,
      }),
    [setCaregiver]
  );

  return (
    <InputAutocompleteGraphQL
      label={I18n.t(
        "javascript.components.transcription.caregiver_autocomplete.label"
      )}
      query={ApplicantsByNameAutocomplete}
      queryVariables={{ roles: ["applicant"], querySize: 10 }}
      valuesFromResponse={valuesFromResponse}
      onChange={onChange}
      required={required}
      value={caregiver}
      name={name}
    />
  );
};

CaregiverAutocomplete.propTypes = {
  /**
   * True if the family selection is required, false otherwise.
   */
  required: PropTypes.bool,
  /**
   * The currently selected caregiver. Contains the value and label of the
   * selected family, as well as the userAgencyProfileId and applicationId
   * of the selected family. Simply pass this back from the value given to
   * the setCaregiver function.
   */
  caregiver: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    applicationId: PropTypes.string,
    userAgencyProfileId: PropTypes.string,
  }),
  /**
   * Callback function to set the selected caregiver. Receives an object
   * with the userAgencyProfileId and applicationId of the selected family.
   * Also receives a value and label for restoring the selected value
   * across remounts.
   */
  setCaregiver: PropTypes.func.isRequired,
  /**
   * The name of the input field.
   */
  name: PropTypes.string,
};

export default CaregiverAutocomplete;
