import { useMutation } from "@apollo/client";
import {
  SurfaceForm,
  InputFilterable,
  InputDate,
  InputDropdown,
  Actions,
  InputMoney,
  InputTime,
  InputText,
  InputTextarea,
  toast,
} from "@heart/components";
import useBintiForm from "@heart/components/forms/useBintiForm";
import PropTypes from "prop-types";
import { useState } from "react";
import { preventionAgencyServiceReferralPath } from "routes";

import { translationWithRoot } from "@components/T";

import CreateAgencyServiceRecord from "@graphql/mutations/prevention/CreateAgencyServiceRecord.graphql";

import preventDefault from "@lib/preventDefault";

const { T, t } = translationWithRoot("prevention.agency_service_records");

/**
 * @param {Array} methodsOfEngagement
 * @param {Number} numUnits
 * @param {Array} agencyServiceRates
 * @param {Array} agencyServiceReferralRecipients
 * @param {String} agencyServiceReferralId
 */
const AgencyServiceRecordForm = ({
  methodsOfEngagement,
  numUnits,
  agencyServiceRates,
  agencyServiceReferralRecipients,
  agencyServiceReferralId,
}) => {
  const minutesBilledIncrement = 15;

  const [minutesBilledError, setMinutesBilledError] = useState(null);
  const [mileageError, setMileageError] = useState(null);
  const [recipientError, setRecipientError] = useState(null);

  const { formState, setFormAttribute } = useBintiForm(null, {
    initialValue: { agencyServiceReferralId },
  });

  const [createAgencyServiceRecord] = useMutation(CreateAgencyServiceRecord, {
    onCompleted: mutationData => {
      if (mutationData.createPreventionAgencyServiceRecord.errors) {
        toast.negative({
          title: t("error_toast.title"),
          message: t("error_toast.description"),
        });
        return;
      }
      window.location = preventionAgencyServiceReferralPath(
        mutationData.createPreventionAgencyServiceRecord.agencyServiceReferral
          .id
      );
    },
  });

  const handleSubmit = () => {
    if (!formState.agencyServiceReferralRecipientIds) {
      setRecipientError(t("errors.recipients_required"));
      return;
    }

    setRecipientError(null);

    if (
      formState.minutesBilled &&
      formState.minutesBilled % minutesBilledIncrement !== 0
    ) {
      setMinutesBilledError(t("errors.minutes_billed_multiple_of_15"));
      return;
    }

    if (formState.minutesBilled && formState.minutesBilled < 0) {
      setMinutesBilledError(t("errors.minutes_billed_positive"));
      return;
    }

    setMinutesBilledError(null);

    if (formState.mileage && formState.mileage < 0) {
      setMileageError(t("errors.mileage_positive"));
      return;
    }

    if (formState.mileage && formState.mileage.includes(".")) {
      setMileageError(t("errors.mileage_integer"));
      return;
    }

    setMileageError(null);

    if (formState.agencyServiceReferralRecipientIds) {
      formState.agencyServiceReferralRecipientIds =
        formState.agencyServiceReferralRecipientIds.map(
          recipient => recipient.value
        );
    }

    // combine start and end times with service date to make them datetimes
    if (formState.serviceDate && formState.startTime && formState.endTime) {
      formState.startTime = new Date(
        `${formState.serviceDate}T${formState.startTime}:00`
      ).toISOString();
      formState.endTime = new Date(
        `${formState.serviceDate}T${formState.endTime}:00`
      ).toISOString();
    }

    createAgencyServiceRecord({
      variables: {
        input: {
          ...formState,
        },
      },
    });
  };

  const selectedRateBillingStructure = formState.agencyServiceRateId
    ? agencyServiceRates.find(
        rate => rate.id.toString() === formState.agencyServiceRateId
      ).billingStructure
    : null;

  return (
    <SurfaceForm
      actions={<Actions />}
      title={<T t="log_new" />}
      onSubmit={preventDefault(handleSubmit)}
    >
      <InputDate
        label={<T t="service_date" />}
        onChange={setFormAttribute("serviceDate")}
        required
      />
      <InputFilterable
        isMulti
        label={<T t="recipients" />}
        onChange={setFormAttribute("agencyServiceReferralRecipientIds")}
        error={recipientError}
        values={agencyServiceReferralRecipients}
        required
      />
      <InputDropdown
        hideBlank
        label={<T t="select_rate" />}
        onChange={setFormAttribute("agencyServiceRateId")}
        required
        values={agencyServiceRates.map(rate => [rate.label, rate.id])}
      />
      <InputDropdown
        hideBlank
        label={<T t="method_of_engagement" />}
        onChange={setFormAttribute("methodOfEngagement")}
        values={methodsOfEngagement}
        required
      />
      <If condition={formState.methodOfEngagement === "Other"}>
        <InputText
          label={<T t="method_of_engagement_other" />}
          onChange={setFormAttribute("methodOfEngagementOther")}
          required
        />
      </If>
      <InputTime
        label={<T t="start_time" />}
        onChange={setFormAttribute("startTime")}
        required
      />
      <InputTime
        label={<T t="end_time" />}
        onChange={setFormAttribute("endTime")}
        required
      />
      <If condition={selectedRateBillingStructure === "hourly"}>
        <InputText
          label={<T t="minutes_billed" />}
          type="number"
          step="15"
          onChange={setFormAttribute("minutesBilled")}
          error={minutesBilledError}
          required
        />
      </If>
      <If condition={selectedRateBillingStructure === "fee_per_service"}>
        <InputDropdown
          hideBlank
          label={<T t="units_utilized" />}
          onChange={setFormAttribute("unitsUtilized")}
          required
          values={Array.from({ length: numUnits + 1 }, (_, i) => [i, i])}
        />
      </If>
      <If condition={selectedRateBillingStructure === "mileage"}>
        <InputText
          label={<T t="mileage" />}
          type="number"
          onChange={setFormAttribute("mileage")}
          error={mileageError}
          required
        />
      </If>
      <If condition={selectedRateBillingStructure === "variable_one_time_fee"}>
        <InputMoney
          label={<T t="amount" />}
          onChange={setFormAttribute("amount")}
          required
        />
      </If>
      <InputTextarea
        label={<T t="notes" />}
        onChange={setFormAttribute("notes")}
        required
      />
    </SurfaceForm>
  );
};

AgencyServiceRecordForm.propTypes = {
  methodsOfEngagement: PropTypes.array,
  numUnits: PropTypes.number,
  agencyServiceRates: PropTypes.array,
  agencyServiceReferralRecipients: PropTypes.array,
  agencyServiceReferralId: PropTypes.string,
};

export default AgencyServiceRecordForm;
