import { Layout } from "@heart/components";

import DataExports from "./DataExports";

/**
 * DataExportsPage component renders the layout for data exports.
 * @returns {JSX.Element} The layout component with data exports content.
 */
const DataExportsPage = () => (
  <Layout pageTitle={"Data Exports"} main={{ content: <DataExports /> }} />
);

export default DataExportsPage;
