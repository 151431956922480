import { useQuery } from "@apollo/client";
import { InputFilterable } from "@heart/components";
import { noop } from "lodash";
import PropTypes from "prop-types";
import { useMemo } from "react";

import { translationWithRoot } from "@components/T";

import SecondarySubdivisionsQuery from "@graphql/queries/SecondarySubdivisions.graphql";

const { t } = translationWithRoot("heart.components.inputs.input_address");

const loadingOrError = (loading, error) => {
  if (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return error || loading;
};

/** ### Usage
 *
 * A select component that allows selection of a secondary subdivision (i.e. counties)
 * of passed country / primary subdivision (i.e. state) pair.
 * Primarily for use within an address form, but can be used independently if only
 * county level granularity is required.
 *
 */
export const SecondarySubdivisionSelect = ({
  value = "",
  name = "secondarySubdivision",
  countryCode,
  primarySubdivisionCode,
  onChange = noop,
  description = null,
  required = false,
}) => {
  const skipFetchingSecondarySubdivision = !(
    countryCode && primarySubdivisionCode
  );

  const { loading, error, data } = useQuery(SecondarySubdivisionsQuery, {
    variables: {
      countryCode: countryCode,
      primarySubdivisionCode: primarySubdivisionCode,
    },
    // if address.countryCode is not set (""), we skip running this query
    skip: skipFetchingSecondarySubdivision,
  });

  const subdivisionList = useMemo(() => {
    if (skipFetchingSecondarySubdivision || loadingOrError(loading, error))
      return [];

    return data.secondarySubdivisions.subdivisions.map(subdivision => ({
      value: subdivision,
      label: subdivision,
    }));
  }, [data, error, loading, skipFetchingSecondarySubdivision]);

  return (
    <InputFilterable
      label={t("secondary_subdivision")}
      description={description}
      required={required}
      values={subdivisionList}
      name={name}
      value={value ? { label: value, value: value } : []}
      onChange={({ value: val }) => {
        onChange({ secondarySubdivision: val });
      }}
    />
  );
};

SecondarySubdivisionSelect.propTypes = {
  /** Optionally pre-select a secondary subdivision by name */
  value: PropTypes.string,

  /** Which primary subdivision to select a secondary subdivision of? */
  primarySubdivisionCode: PropTypes.string,

  /* Which country? If not provided it will simply be a blank select. */
  countryCode: PropTypes.string.isRequired,

  /* Optionally name the html field; likely for activeadmin use cases */
  name: PropTypes.string,

  /** The `onChange` function is invoked with the subdivision is value */
  onChange: PropTypes.func,

  /** An optional description to display under the label */
  description: PropTypes.string,

  /** Is this field required? By default it is not */
  required: PropTypes.bool,
};

export const { propTypes } = SecondarySubdivisionSelect;

export default SecondarySubdivisionSelect;
