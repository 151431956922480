import { Text } from "@heart/components";
import Clickable from "@heart/components/clickable/Clickable";
import classNames from "classnames";
import { isNil } from "lodash";
import React from "react";

import styles from "./sidebarItem.module.scss";

/** A helper function for generating sidebar items,
 * returning id, content, and icon (when provided)
 * for a given link in a sidebar
 */
const sidebarItem = ({
  currentPage,
  navigateToPage = () => {},
  pageId,
  pageTitle,
  href,
  Icon,
  useCurrentPageIndicator = true,
}) => {
  const isCurrentPage = pageId === currentPage;
  const textProps = {
    textColor: isCurrentPage ? "emphasis-500" : "neutral-600",
    textStyle: isCurrentPage ? "emphasis-100" : "body-100",
  };
  const onClick = isNil(href)
    ? () => navigateToPage({ page: pageId })
    : undefined;

  let IconComponent = null;
  if (Icon)
    IconComponent = (
      <Text
        {...textProps}
        className={classNames({
          [styles.activePageIndicator]:
            isCurrentPage && useCurrentPageIndicator,
        })}
      >
        <Icon
          description={pageTitle}
          href={isCurrentPage ? undefined : href}
          onClick={isCurrentPage ? undefined : onClick}
          aria-current={isCurrentPage ? "page" : undefined}
        />
      </Text>
    );

  const ContentComponent = (
    <Clickable
      className={classNames(styles.removeClickableStyles, {
        [styles.activePageIndicator]: isCurrentPage && useCurrentPageIndicator,
      })}
      href={isCurrentPage ? undefined : href}
      onClick={isCurrentPage ? undefined : onClick}
      aria-current={isCurrentPage ? "page" : undefined}
    >
      <Text {...textProps}>{pageTitle}</Text>
    </Clickable>
  );
  return { id: pageId, content: ContentComponent, icon: IconComponent };
};

export default sidebarItem;
