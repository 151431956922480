import { useQuery } from "@apollo/client";
import { InputFilterable, InputHidden } from "@heart/components";
import { isEmpty, keyBy, mapValues, noop } from "lodash";
import PropTypes from "prop-types";
import { useEffect, useMemo } from "react";

import { translationWithRoot } from "@components/T";

import CountriesQuery from "@graphql/queries/Countries.graphql";

const { t } = translationWithRoot("heart.components.inputs.input_address");

const loadingOrError = (loading, error) => {
  if (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return error || loading;
};

/** ### Usage
 *
 * A select component that allows selection of a secondary subdivision (i.e. counties)
 * of passed country / primary subdivision (i.e. state) pair.
 * Primarily for use within an address form, but can be used independently if only
 * county level granularity is required.
 *
 */
export const CountrySelect = ({
  selectedCountryCode = "",
  name = "country",
  onChange = noop,
  allowedCountryCodes = null,
  description = null,
  required = true,
}) => {
  const { data, loading, error } = useQuery(CountriesQuery);

  // remove countries not in allowedCountries. memoizing just as there's much recalculation
  // in this component.
  const countryList = useMemo(() => {
    if (loadingOrError(loading, error)) return [];
    if (allowedCountryCodes) {
      return data.countries.filter(c => allowedCountryCodes.includes(c.code));
    }
    return data.countries;
  }, [data, loading, error, allowedCountryCodes]);

  const countryMap = useMemo(
    () => mapValues(keyBy(countryList, "code"), country => country.name),
    [countryList]
  );

  const getCountryValue = () => {
    if (!isEmpty(countryList) && selectedCountryCode) {
      return {
        label: countryMap[selectedCountryCode],
        value: selectedCountryCode,
      };
    }
    return [];
  };

  useEffect(() => {
    if (countryList.length === 1 && !selectedCountryCode) {
      onChange({ code: countryList[0].code, name: countryList[0].name });
    }
  }, [countryList, selectedCountryCode, onChange]);

  return (
    <div>
      <If condition={countryList.length !== 1}>
        <InputFilterable
          label={t("country")}
          description={description}
          required={required}
          values={
            countryList
              ? countryList.map(({ code, name: countryName }) => ({
                  label: countryName,
                  value: code,
                }))
              : []
          }
          name={name}
          value={getCountryValue()}
          onChange={({ value, label }) =>
            onChange({ code: value, name: label })
          }
        />
      </If>
      <If condition={countryList.length === 1}>
        <InputHidden
          label={t("country")}
          required
          name={name}
          value={getCountryValue()?.code}
        />
      </If>
    </div>
  );
};

CountrySelect.propTypes = {
  /* Optionally preselect a country */
  selectedCountryCode: PropTypes.string,

  /* Only allow the following countries; if only 1 is listed the input will be hidden.
   * If not provided, a list of all known countries will be fetched from the backend.
   */
  allowedCountryCodes: PropTypes.arrayOf(PropTypes.string),

  /* Optionally name the html field; likely for activeadmin use cases */
  name: PropTypes.string,

  /** The `onChange` function is invoked when the country is selected */
  onChange: PropTypes.func,

  /** An optional description to display under the label */
  description: PropTypes.string,

  /** Is this field required? By default it is  */
  required: PropTypes.bool,
};

export const { propTypes } = CountrySelect;

export default CountrySelect;
