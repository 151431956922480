import colorStyles from "@heart/core/colors.module.scss";
import typographyStyles from "@heart/core/typography.module.scss";
import classNames from "classnames";
import PropTypes from "prop-types";
import { ElementType, ReactNode } from "react";

import styles from "./Text.module.scss";

// The available `color` props are the CSS class names in `colors.module.scss`
// but without the `text-color-` or `bg-color-` prefix.
export const availableTextColors = Object.keys(colorStyles)
  .filter(rule => rule.startsWith("text-color-"))
  .map(rule => rule.replace("text-color-", ""));

// The available `type` props are the CSS class names in `typography.module.scss`
// but without the `typography-` prefix.
export const availableTextStyles = Object.keys(typographyStyles).map(rule =>
  rule.replace("typography-", "")
);

/**
 * Stylized text component using our design system.
 *
 * Note: Environment and Map colors are not intended for use within Binti outside of
 * our environment logos and family maps
 *
 * @param textColor Which color value this Text should have
 * @param textStyle Which typography this Text should have
 * @param as What kind of DOM element to use.  Defaults to `<span>`
 * @param children The content of this Text
 * @param className Additional classes to customize the appearance of this Text.
 *                *Please don't use this to change the font size/weight/etc.*
 */
const Text = ({
  as: Component = "span",
  className,
  children,
  textColor,
  textStyle,
  ...props
}: {
  as?: ElementType;
  className?: string;
  children: ReactNode;
  textColor?: string;
  textStyle?: string;
  [key: string]: any;
}) => (
  <Component
    className={classNames(
      className,
      {
        [colorStyles[`text-color-${textColor}`]]: textColor,
        [typographyStyles[`typography-${textStyle}`]]: textStyle,
      },
      {
        [styles.defeatHeaderStyles]: ["h1", "h2", "h3"].includes(
          Component.toString()
        ),
      }
    )}
    {...props}
  >
    {children}
  </Component>
);

export default Text;
