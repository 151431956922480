import { useMutation, useReactiveVar } from "@apollo/client";
import { Modal, Flex, FlexItem, Button } from "@heart/components";
import GraphQLDataTable from "@heart/components/data_table/GraphQLDataTable";
import { isNil, snakeCase } from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";
import { newFamilyFindingContactLogPath } from "routes";

import { translationWithRoot } from "@components/T";
import { queryVariablesVar } from "@components/shared/BintiApolloProvider";

import DeleteFamilyFindingContactLog from "@graphql/mutations/DeleteFamilyFindingContactLog.graphql";
import AgencyHumanRelationshipsWithContactLogQuery from "@graphql/queries/AgencyHumanRelationshipsWithContactLog.graphql";
import FamilyFindingContactLogsForChildQuery from "@graphql/queries/FamilyFindingContactLogsForChild.graphql";

import { policy } from "@lib/graphqlHelpers";

import ViewModal from "./ViewModal";
import tableColumns from "./tableColumns";
import tableFilters from "./tableFilters";

const { t: commonT } = translationWithRoot("common", {
  escapeJavascriptRoot: true,
});

const { t: contactLogsT } = translationWithRoot("family_finding.contact_logs");

const ContactLogsTable = ({
  childAgencyHumanId,
  editContactLogBasePath,
  downloadContactLogsCsvPath,
}) => {
  const queryVariables = useReactiveVar(queryVariablesVar);
  const defaultFilters = {
    childAgencyHumanId,
  };
  const defaultSort = {
    sortBy: "contacted_on",
    sortDirection: "DESC",
  };
  const addDefaultQueryParams = queryParams => ({
    ...defaultSort,
    ...defaultFilters,
    ...queryParams,
  });

  const [deleteFamilyFindingContactLog, { loading: deleting }] = useMutation(
    DeleteFamilyFindingContactLog,
    {
      // After deleting a contact log, refetch query used to
      // populate the Relationships table, which includes
      // data on the latest contact log per relationship
      // and could now be out-dated
      refetchQueries: [
        {
          query: AgencyHumanRelationshipsWithContactLogQuery,
          variables: { agencyHumanId: childAgencyHumanId },
        },
        {
          query: FamilyFindingContactLogsForChildQuery,
          variables: queryVariables,
        },
      ],
    }
  );

  const [contactLogToView, setContactLogToView] = useState();
  const [contactLogIdToDelete, setContactLogIdToDelete] = useState();
  const [csvUrl, setCsvUrl] = useState(
    `${downloadContactLogsCsvPath}?child_agency_human_id=${childAgencyHumanId}`
  );
  const [permissions, setPermissions] = useState([]);
  const onQueryCompleted = results => {
    setPermissions(results.familyFindingContactLogsForChild.permissions);
  };

  return (
    <Flex column gap="300">
      <ViewModal
        contactLog={contactLogToView}
        setContactLogToView={setContactLogToView}
        childAgencyHumanId={childAgencyHumanId}
      />
      <Modal
        title={contactLogsT("contact_logs_table.delete_modal.title")}
        submitting={deleting}
        submitDangerButton
        submitText={contactLogsT("contact_logs_table.delete_modal.yes_delete")}
        submittingText={contactLogsT(
          "contact_logs_table.delete_modal.deleting"
        )}
        onCancel={() => setContactLogIdToDelete()}
        onSubmit={async () => {
          await deleteFamilyFindingContactLog({
            variables: { id: contactLogIdToDelete },
          });
          setContactLogIdToDelete(undefined);
        }}
        hidden={isNil(contactLogIdToDelete)}
      >
        <Flex column>
          <FlexItem>
            {contactLogsT("contact_logs_table.delete_modal.are_you_sure")}
          </FlexItem>
          <FlexItem>
            {contactLogsT("contact_logs_table.delete_modal.delete_help_text")}
          </FlexItem>
        </Flex>
      </Modal>
      <GraphQLDataTable
        title={contactLogsT("contact_logs_table.title")}
        query={FamilyFindingContactLogsForChildQuery}
        queryTitle="familyFindingContactLogsForChild"
        filters={tableFilters()}
        actions={
          <If condition={policy({ permissions }).mayCreate()}>
            <Flex justify="center" column>
              <Button
                variant="primary"
                href={`${newFamilyFindingContactLogPath()}?child_agency_human_id=${childAgencyHumanId}`}
              >
                {contactLogsT("common.record_contact")}
              </Button>
            </Flex>
          </If>
        }
        defaultFilters={defaultFilters}
        defaultSort={defaultSort}
        columns={tableColumns(
          setContactLogToView,
          setContactLogIdToDelete,
          editContactLogBasePath,
          childAgencyHumanId
        )}
        onFilterChange={filters => {
          const allFilters = addDefaultQueryParams(filters);
          const queryParamz = new URLSearchParams(
            Object.fromEntries(
              Object.entries(allFilters)
                .filter(([, value]) => !isNil(value))
                .map(([key, value]) => [snakeCase(key), value])
            )
          );
          setCsvUrl(`${downloadContactLogsCsvPath}?${queryParamz}`);
        }}
        onQueryCompleted={onQueryCompleted}
        filtersToPreserveOnClear={["childAgencyHumanId"]}
      />
      <Flex justify="center">
        <Button variant="secondary" href={csvUrl}>
          {commonT("download_csv")}
        </Button>
      </Flex>
    </Flex>
  );
};

ContactLogsTable.propTypes = {
  childAgencyHumanId: PropTypes.number.isRequired,
  editContactLogBasePath: PropTypes.string.isRequired,
  downloadContactLogsCsvPath: PropTypes.string.isRequired,
};

export default ContactLogsTable;
