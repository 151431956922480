import { Breadcrumbs } from "@heart/components";
import I18n from "i18n-js";
import PropTypes from "prop-types";
import { adminChildrenPath } from "routes";

/** Breadcrumbs for child pages that use a full page layout
 * and the ChildSidebar
 */
const ChildBreadcrumbs = ({ childId, childName }) => (
  <Breadcrumbs
    pages={[
      {
        href: adminChildrenPath({ q: { id_eq: childId }, scope: "all" }),
        label: I18n.t("admin.header_links.children_youth"),
      },
      { href: "#", label: childName },
    ]}
  />
);
ChildBreadcrumbs.propTypes = {
  childId: PropTypes.number.isRequired,
  childName: PropTypes.string.isRequired,
};

export default ChildBreadcrumbs;
