import { Modal, DetailsTable } from "@heart/components";
import { isNil, isEmpty } from "lodash";
import PropTypes from "prop-types";

import { translationWithRoot } from "@components/T";

import { formatDateTimeAsShortDate } from "@lib/dates";

import { OTHER, WORKER } from "@root/constants";

import {
  determineName,
  determineRelationshipTypeForTable,
  filterLevelsOfSupport,
} from "./sortRelationships";

const { t: enumT } = translationWithRoot("activerecord.enums.relationships", {
  escapeJavascriptRoot: true,
});
const { t: relationshipT } = translationWithRoot(
  "relationships.table.view_modal"
);

const ViewRelationshipModal = ({
  keystoneAgencyHumanId,
  relationship,
  setRelationshipToView,
}) => {
  if (isNil(relationship)) return null;

  const {
    destinationAgencyHuman,
    sourceAgencyHuman,
    parentalLine,
    levelsOfSupport,
    placementProviderEligibilities = [],
    placementProviderEligibilitiesDetails,
    emotionalRelationshipStatuses = [],
    additionalRelationshipDetails,
    verificationSource,
    updatedAt,
  } = relationship;
  return (
    <Modal
      title={relationshipT("title", {
        name: determineName({
          keystoneAgencyHumanId,
          destinationAgencyHuman,
          sourceAgencyHuman,
        }).name,
      })}
      submitText={relationshipT("close")}
      onCancel={() => setRelationshipToView()}
      onSubmit={() => setRelationshipToView()}
      hidden={isNil(relationship)}
      hideCancel={true}
    >
      <DetailsTable
        caption={relationshipT("caption")}
        hideCaption={true}
        details={[
          {
            label: relationshipT("relationship_type"),
            value: determineRelationshipTypeForTable(relationship),
          },
          {
            label: relationshipT("parental_line"),
            value: isNil(parentalLine)
              ? ""
              : parentalLine
                  .split("_and_")
                  .map(line => enumT(`parental_lines.${line}`))
                  .join(", "),
          },
          {
            label: relationshipT("placement_option"),
            value: `${placementProviderEligibilities
              .map(ppe => enumT(`placement_provider_eligibilities.${ppe}`))
              .join(", ")}${
              isEmpty(placementProviderEligibilitiesDetails)
                ? ""
                : ` - ${placementProviderEligibilitiesDetails}`
            }`,
          },
          {
            label: relationshipT("level_of_support"),
            value: filterLevelsOfSupport(levelsOfSupport)
              .map(los => {
                if (los === OTHER) return levelsOfSupport[OTHER].details;
                return enumT(`level_of_support.${los}`);
              })
              .join(", "),
          },
          {
            label: relationshipT("emotional_relationship_status"),
            value: emotionalRelationshipStatuses
              .map(status => enumT(`emotional_status.${status}`))
              .join(", "),
          },
          {
            label: relationshipT("additional_details"),
            value: additionalRelationshipDetails,
          },
          {
            label: relationshipT("verified_by_worker"),
            value: verificationSource === WORKER ? "Yes" : "No",
          },
          {
            label: relationshipT("last_updated"),
            value: formatDateTimeAsShortDate(updatedAt),
          },
        ]}
      />
    </Modal>
  );
};
ViewRelationshipModal.propTypes = {
  keystoneAgencyHumanId: PropTypes.number.isRequired,
  relationship: PropTypes.shape({
    destinationAgencyHuman: PropTypes.shape({
      id: PropTypes.string,
      fullName: PropTypes.string,
    }),
    sourceAgencyHuman: PropTypes.shape({ fullName: PropTypes.string }),
    parentalLine: PropTypes.string,
    lineageType: PropTypes.string,
    levelsOfSupport: PropTypes.object,
    placementProviderEligibilities: PropTypes.array,
    placementProviderEligibilitiesDetails: PropTypes.string,
    emotionalRelationshipStatuses: PropTypes.arrayOf(PropTypes.string),
    additionalRelationshipDetails: PropTypes.string,
    verificationSource: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
  setRelationshipToView: PropTypes.func.isRequired,
};

export default ViewRelationshipModal;
