import { LoadingOverlay } from "@heart/components";
import { useWindowSize } from "@react-hookz/web";
import { isEmpty } from "lodash";
import {
  IframeHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

/**
 * An iframe that automatically resizes its height to fit its content both after the
 * content loads and when the window resizes.
 *
 * By default, the iframe will resize to fit the entire body of the content. If you
 * want to resize to a specific element within the content, you can pass a CSS selector
 * to the `heightSelector` prop.
 *
 * NOTE: You must pass a `title` prop to the iframe. This is required for accessibility.
 * This component will refuse to render if you don't pass a title.
 *
 * @param {IframeHTMLAttributes<HTMLIFrameElement>} props the props to pass to the iframe.
 * @param {string} [props.heightSelector] a CSS selector for an element within the iframe to resize to.
 * @param {boolean} [props.showLoadingOverlay] whether to show a loading overlay while the iframe is loading.
 */
const AutoSizedIFrame = (
  props: IframeHTMLAttributes<HTMLIFrameElement> & {
    heightSelector?: string;
    showLoadingOverlay?: boolean;
  }
) => {
  const [active, setIsActive] = useState(true);
  const iframeRef = useRef<HTMLIFrameElement>(null);

  // extract title explicitly to make jsx-a11y happy
  const {
    title,
    heightSelector,
    showLoadingOverlay = false,
    ...iframeProps
  } = props;

  const setIframeHeight = useCallback(() => {
    if (iframeRef.current) {
      if (heightSelector) {
        const height =
          iframeRef.current.contentWindow?.document?.querySelector(
            heightSelector
          )?.clientHeight;
        iframeRef.current.height = `${height}px`;
      } else {
        iframeRef.current.height = `${iframeRef.current.contentWindow?.document?.body?.scrollHeight}px`;
      }
    }
    setIsActive(false);
  }, [heightSelector]);

  const { width, height } = useWindowSize();

  // re-set the iframe height when the window resizes
  // this is necessary because the content might change size
  // when the window resizes
  useEffect(() => {
    setIframeHeight();
  }, [width, height, setIframeHeight]);

  // be noisy about a missing title - passing this from react_component
  // might not be enough for TypeScript to catch it since that happens at runtime
  // and between ruby and JS.
  if (isEmpty(title)) throw new Error("AutoSizedIFrame requires a title prop");

  if (showLoadingOverlay) {
    return (
      <LoadingOverlay active={active}>
        <iframe
          title={title}
          {...iframeProps}
          ref={iframeRef}
          onLoad={setIframeHeight}
        />
      </LoadingOverlay>
    );
  }

  return (
    <iframe
      title={title}
      {...iframeProps}
      ref={iframeRef}
      onLoad={setIframeHeight}
    />
  );
};

export default AutoSizedIFrame;
