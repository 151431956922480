import {
  GraphQLDataTable,
  Surface,
  Link,
  Flex,
  Icons,
} from "@heart/components";

import DataExportsQuery from "@graphql/queries/DataExports.graphql";

/**
 * DataExports component renders a table of data exports with filters and columns.
 */
const DataExports = () => (
  <Surface hideTitle title="Data Exports">
    <GraphQLDataTable
      query={DataExportsQuery}
      queryTitle="dataExports"
      filters={[
        {
          label: "Export Title",
          type: "search",
          field: "titleNameContains",
        },
        {
          label: "Requester Name",
          type: "search",
          field: "requesterNameContains",
        },
        {
          label: "Agency Name",
          type: "search",
          field: "agencyNameContains",
        },
      ]}
      columns={[
        {
          columnName: { name: "Requester" },
          id: "requester_name",
          sortBy: "requester_name",
          cell: ({ requester }) => (
            <Link href={requester.linkToView}>{requester.name}</Link>
          ),
        },
        {
          columnName: { name: "Agency" },
          id: "agency_name",
          sortBy: "agency_name",
          cell: ({ agency }) => (
            <Link href={agency.linkToView}>{agency.name}</Link>
          ),
        },
        {
          columnName: { name: "Export Title" },
          id: "title",
          sortBy: "title",
          cell: "title",
        },
        {
          columnName: { name: "Requested At" },
          id: "requested_at",
          sortBy: "requested_at",
          cell: "requestedAt",
        },
        {
          columnName: { name: "Download" },
          id: "download_link",
          cell: ({ downloadLink }) => (
            <Flex>
              <Icons.FileDownload description="Download" href={downloadLink} />
            </Flex>
          ),
        },
      ]}
    />
  </Surface>
);

DataExports.propTypes = {};

export default DataExports;
