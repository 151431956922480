import { useMutation, useReactiveVar } from "@apollo/client";
import { Alert, UploadButton } from "@heart/components";
import I18n from "i18n-js";
import React, { useState } from "react";

import { translationWithRoot } from "@components/T";
import { queryVariablesVar } from "@components/shared/BintiApolloProvider";

import RegisterChildDocuments from "@graphql/mutations/prevention/RegisterChildDocuments.graphql";
import AgencyHumanRequirementReconciliation from "@graphql/queries/prevention/AgencyHumanRequirementReconciliation.graphql";

import useShrine from "@lib/useShrine";

const { t } = translationWithRoot(
  "prevention.profile_page.documents_and_attachments_tab"
);

const attachmentError = path => `attachments.errors.${path}`;

/**
 * Creates upload button and alert modal for uploading documents for a child in
 * Prevention
 *
 * @param agencyHumanId
 * @returns {{AlertModal: React.JSX.Element, UploadButton: React.JSX.Element}}
 */
export default agencyHumanId => {
  const queryVariables = useReactiveVar(queryVariablesVar);
  const [modalText, setModalText] = useState(null);
  const [registerDocuments, { error, data, called, reset }] = useMutation(
    RegisterChildDocuments,
    {
      refetchQueries: [
        {
          query: AgencyHumanRequirementReconciliation,
          variables: queryVariables,
        },
      ],
    }
  );

  if (called) {
    if (error) {
      setModalText(attachmentError("generic_error"));
    } else if (
      data?.preventionRegisterChildDocuments?.errors?.length &&
      modalText === null
    ) {
      const virusError = data.preventionRegisterChildDocuments.errors.find(
        gqError => gqError.message.match(/virus/i)
      );

      if (virusError) {
        setModalText(attachmentError("virus_detected"));
      } else {
        setModalText(attachmentError("generic_error"));
      }
    }
    reset();
  }

  const { prepareShrineFiles, ShrineErrorAlert } = useShrine();
  const onUpload = async files => {
    const shrineFiles = await prepareShrineFiles(files);

    // Not all files were uploaded.
    if (shrineFiles.length < files.length) {
      return setModalText(attachmentError("generic_error"));
    }

    if (shrineFiles.length) {
      registerDocuments({
        variables: { agencyHumanId, files: shrineFiles },
      });
    }

    return null;
  };

  const AlertModal = () => (
    <Alert
      hidden={!modalText}
      onSubmit={() => {
        if (called) {
          reset();
        }
        setModalText(null);
      }}
      isAlert={true}
      title={I18n.t("javascript.components.common.alert")}
      submitText={I18n.t(attachmentError("ok"))}
    >
      {/* We are setting a generic error message otherwise
        I18n will throw when given a null value and Alert will complain about not giving
         it a child */}
      {I18n.t(modalText || attachmentError("generic_error"))}
    </Alert>
  );

  const UploadBtn = () => (
    <UploadButton onUpload={onUpload} label={t("upload_documents")} />
  );

  return { ShrineErrorAlert, AlertModal, UploadButton: UploadBtn };
};
