import { InputRadioGroup, Surface } from "@heart/components";

import { translationWithRoot } from "@components/T";

import { CASE_NOTE, FORM, TranscriptionType } from "./constants";

const { t } = translationWithRoot(
  "transcription.select_transcription_type_page"
);

/**
 * Form for creating a new transcription process for a Binti form.
 */
const SelectTranscriptionTypePage = ({
  transcriptionType,
  setTranscriptionType,
}: {
  transcriptionType?: TranscriptionType;
  setTranscriptionType: (newTranscriptionType: TranscriptionType) => void;
}) => (
  <Surface title={t("title")} hideTitle>
    <InputRadioGroup
      label={t("transcription_type")}
      required
      values={[
        { value: CASE_NOTE, label: t("transcription_type_case_note") },
        { value: FORM, label: t("transcription_type_form") },
      ]}
      value={transcriptionType}
      onChange={setTranscriptionType}
    />
  </Surface>
);

export default SelectTranscriptionTypePage;
