import { Flex, FlexItem, Text } from "@heart/components";
import listStyles from "@heart/core/invisible_list.module.scss";
import { If } from "tsx-control-statements/components";

export const LabeledContent = ({
  label,
  content,
}: {
  label: string | React.ReactNode;
  content: any;
}) => {
  if (content) {
    return (
      <Text>
        <If condition={label}>
          <b>{label}</b>:{" "}
        </If>
        {content}
      </Text>
    );
  }
  return null;
};

export const TableCellList = ({
  gap = "100",
  items,
  transformItem,
}: {
  gap: "100" | "200" | "300";
  items: any[];
  transformItem: (item: any) => any;
}) => (
  <Flex column gap={gap} as="ul" className={listStyles.invisibleList}>
    {items &&
      items.map((item, index) => (
        <FlexItem key={index} as="li">
          {transformItem ? transformItem(item) : item}
        </FlexItem>
      ))}
  </Flex>
);
