import { Modal, DetailsTable, Pill, Link } from "@heart/components";
import { isNil } from "lodash";
import PropTypes from "prop-types";
import { contentFamilyFindingContactLogPath } from "routes";

import { translationWithRoot } from "@components/T";

import {
  formatDateTimeAsShortDate,
  formatDateTimeAsShortTime,
} from "@lib/dates";

import contactStatusMap from "./contactStatusMap";

const { t: contactLogEnumT } = translationWithRoot(
  "activerecord.enums.family_finding_contact_log",
  {
    escapeJavascriptRoot: true,
  }
);
const { t: contactLogsT } = translationWithRoot("family_finding.contact_logs");

/** Modal for viewing details of a contact log from the contact logs dashboard */
const ViewModal = ({ contactLog, setContactLogToView, childAgencyHumanId }) => {
  if (isNil(contactLog)) return null;

  const {
    id,
    contactedOn,
    contactedOnTimeKnown,
    contactMethod,
    status,
    notes,
    familyFindingContactLogsChildAgencyHumans,
    familyFindingContactLogsContactAgencyHumans,
    familyFindingBulkOutreachCampaignId,
  } = contactLog;

  return (
    <Modal
      title={contactLogsT("common.contact_details")}
      submitText={contactLogsT("contact_logs_table.view_modal.close")}
      onCancel={() => setContactLogToView()}
      onSubmit={() => setContactLogToView()}
      hidden={isNil(contactLog)}
      hideCancel={true}
    >
      <DetailsTable
        caption={contactLogsT("common.contact_details")}
        hideCaption={true}
        details={[
          {
            label: contactLogsT(
              "contact_logs_table.view_modal.binti_contacted"
            ),
            value: familyFindingContactLogsContactAgencyHumans
              .reduce(
                (acc, { contactAgencyHuman }) =>
                  !isNil(contactAgencyHuman)
                    ? [...acc, contactAgencyHuman.fullName]
                    : acc,
                []
              )
              .join(", "),
          },
          {
            label: contactLogsT(
              "contact_logs_table.view_modal.others_contacted"
            ),
            // There is only ever one familyFindingContactLogsContactAgencyHuman record
            // with a non-null contactName
            value:
              familyFindingContactLogsContactAgencyHumans.find(
                ({ contactName }) => !isNil(contactName)
              )?.contactName || "",
          },
          {
            label: contactLogsT("common.on_behalf_of"),
            value: [...familyFindingContactLogsChildAgencyHumans]
              // Sort so that the name of the child whose dashboard
              // we are on comes first
              .sort(
                (a, b) =>
                  (b.childAgencyHuman.id === childAgencyHumanId) -
                  (a.childAgencyHuman.id === childAgencyHumanId)
              )
              .map(record => record.childAgencyHuman.fullName)
              .join(", "),
          },
          {
            label: contactLogsT("common.date_contacted"),
            value: formatDateTimeAsShortDate(contactedOn),
          },
          {
            label: contactLogsT("common.time_contacted"),
            value: contactedOnTimeKnown
              ? formatDateTimeAsShortTime(contactedOn)
              : null,
          },
          {
            label: contactLogsT("common.contact_method"),
            value: contactLogEnumT(`contact_method.${contactMethod}`),
          },
          {
            label: contactLogsT("common.status"),
            value: (
              <Pill
                variant={contactStatusMap[status]}
                text={contactLogEnumT(`status.${status}`)}
              />
            ),
          },
          {
            label: contactLogsT("common.notes"),
            value: familyFindingBulkOutreachCampaignId ? (
              <Link
                href={contentFamilyFindingContactLogPath(id)}
                target="_blank"
              >
                {notes}
              </Link>
            ) : (
              notes
            ),
          },
        ]}
      />
    </Modal>
  );
};
ViewModal.propTypes = {
  contactLog: PropTypes.shape({
    id: PropTypes.string.isRequired,
    contactedOn: PropTypes.string.isRequired,
    contactedOnTimeKnown: PropTypes.bool.isRequired,
    contactMethod: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    notes: PropTypes.string,
    familyFindingBulkOutreachCampaignId: PropTypes.string,
    familyFindingContactLogsChildAgencyHumans: PropTypes.arrayOf(
      PropTypes.shape({
        fullName: PropTypes.string,
      })
    ).isRequired,
    familyFindingContactLogsContactAgencyHumans: PropTypes.arrayOf(
      PropTypes.shape({
        contactAgencyHuman: PropTypes.shape({
          fullName: PropTypes.string,
        }),
        contactName: PropTypes.string,
      })
    ).isRequired,
  }),
  setContactLogToView: PropTypes.func.isRequired,
  childAgencyHumanId: PropTypes.number.isRequired,
};

export default ViewModal;
