import { Alert } from "@heart/components";
import AwsS3 from "@uppy/aws-s3";
import Uppy from "@uppy/core";
import I18n from "i18n-js";
import { useState } from "react";

const toShrineFile = ({ data: file, uploadURL }) => ({
  // Shrine expects just the file name and then it gets the bucket from the backend.
  // This prevents someone from tricking us into writing to a different bucket or
  // folder than we expected.
  id: uploadURL.match(/\/([^/]+)$/)[1],
  filename: file.name,
});

/**
 * Given a list of files, uploads them to Shrine and returns the resulting
 * ShrineFile metadata objects to include in a mutation.
 *
 * @param {File[]} files the files to upload.
 * @returns {Promise<ShrineFile[]>} the shrine file metadata objects.
 */
const prepareShrineFiles = async files => {
  const uppy = new Uppy().use(AwsS3, { companionUrl: "/" });

  uppy.addFiles(files);

  const { successful, failed } = await uppy.upload();

  if (failed.length > 0) {
    throw new Error(
      `Failed to upload files: ${failed.map(({ error }) => error)}`
    );
  }

  return successful.map(toShrineFile);
};

/**
 * Hook for uploading Shrine files and handling upload errors to google
 * cloud storage.
 *
 * @returns {Object} an object with the following properties:
 * - prepareShrineFiles: a function that uploads files to Shrine and returns
 *   the resulting ShrineFile metadata objects.
 * - ShrineErrorAlert: an Alert component that displays an error message
 *   when a Shrine upload fails. Mount this in your component where you use
 *   the prepareShrineFiles function.
 */
export default () => {
  const [hidden, setHidden] = useState(true);

  const ShrineErrorAlert = () => (
    <Alert
      type="error"
      submitText={I18n.t("views.common.close")}
      onSubmit={() => setHidden(true)}
      title={I18n.t("javascript.graphql_helpers.shrine_error_alert.title")}
      hidden={hidden}
    >
      {I18n.t("javascript.graphql_helpers.shrine_error_alert.body")}
    </Alert>
  );

  const errorHandlingPrepareShrineFiles = async files => {
    try {
      return await prepareShrineFiles(files);
    } catch (error) {
      setHidden(false);
      throw error;
    }
  };

  return {
    prepareShrineFiles: errorHandlingPrepareShrineFiles,
    ShrineErrorAlert,
  };
};
