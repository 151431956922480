import { gql, useQuery, useMutation } from "@apollo/client";
import {
  Button,
  Flex,
  FlexItem,
  Modal,
  ArrayDataTable,
  LoadingOverlay,
  ButtonMenu,
  Icons,
  Toggle,
} from "@heart/components";
import I18n from "i18n-js";
import { isEmpty, isNil } from "lodash";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import {
  familyFindingClearPersonSearchPath,
  newFamilyFindingBulkOutreachCampaignPath,
} from "routes";

import { translationWithRoot } from "@components/T";

import CreateClearPersonSearch from "@graphql/mutations/CreateClearPersonSearch.graphql";
import DeleteRelationship from "@graphql/mutations/DeleteRelationship.graphql";
import AgencyHumanRelationshipsWithContactLogQuery from "@graphql/queries/AgencyHumanRelationshipsWithContactLog.graphql";

import { addEncodedBase64SearchParams } from "@lib/base64SearchParams";
import useBase64SearchParam from "@lib/react-use/useBase64SearchParam";
import useSearchParam from "@lib/react-use/useSearchParam";
import useFeatureFlag from "@lib/useFeatureFlag";

import {
  B64PARAMS,
  UNKNOWN,
  NO_RELATIONSHIP,
  FAMILY_FINDING_BULK_OUTREACH_CAMPAIGN_EMAIL,
  FAMILY_FINDING_BULK_OUTREACH_CAMPAIGN_PRINT,
} from "@root/constants";

import useAgencyHumanPermissions from "../../agency_humans/useAgencyHumanPermissions";
import ConfirmationModal from "../potential_kin_search/ConfirmationModal";
import ViewRelationshipModal from "./ViewRelationshipModal";
import getColumns from "./relationshipsColumns";
import { determineName } from "./sortRelationships";

const { t: relationshipT } = translationWithRoot("relationships.table");

export const CanCreateBulkOutreachCampaignQuery = gql`
  query Can($resource: String!, $action: String!) {
    can(resource: $resource, action: $action)
  }
`;

/** A table displaying relationship information between individuals
 *
 * This component can be used to display potential connections or
 * all non-potential relationships on the basis of the value of the `tab` prop
 */
const RelationshipsTable = ({
  tab,
  familyFindingEnabled,
  addPersonFormPath,
  keystoneAgencyHumanId,
  downloadRelationshipsCsvPath,
}) => {
  const [idForSearchConfirmationModal, setIdForSearchConfirmationModal] =
    useState();
  const contactNameFilter = useSearchParam("contactName") || "";
  const relationshipTypeFilter = useSearchParam("relationshipType") || "";
  const levelsOfSupportFilter = useSearchParam("levelsOfSupport") || "";
  const placementOptionFilter = useSearchParam("placementOption") || "";
  const emotionalRelationshipFilter =
    useSearchParam("emotionalRelationship") || "";
  const selectedIds = useBase64SearchParam("selectedIds") || [];

  const paramsForCSV = new URLSearchParams({
    nameContains: contactNameFilter,
    relationshipTypeContains: relationshipTypeFilter,
    levelsOfSupportIncludes: levelsOfSupportFilter,
    placementProviderEligibilitiesIncludes: placementOptionFilter,
    emotionalRelationshipIncludes: emotionalRelationshipFilter,
  });

  const { flag: ffAddPotentialConnections012024 } = useFeatureFlag(
    "ff_add_potential_connections_01_2024"
  );
  const { flag: ffIntegratedSearch } = useFeatureFlag(
    "ff_integrated_search_01_2024"
  );
  const { flag: ffBulkOutreachPrint } = useFeatureFlag(
    "ff_bulk_outreach_campaigns_07_2024"
  );
  const { flag: ffBulkOutreachEmail } = useFeatureFlag(
    "ff_bulk_outreach_email_12_2024"
  );
  const { flag: ffGenogram102024 } = useFeatureFlag("ff_genogram_10_2024");
  const refetchQueries = [
    {
      query: AgencyHumanRelationshipsWithContactLogQuery,
      variables: { agencyHumanId: keystoneAgencyHumanId },
    },
  ];
  const { data, loading } = useQuery(
    AgencyHumanRelationshipsWithContactLogQuery,
    {
      variables: { agencyHumanId: keystoneAgencyHumanId },
    }
  );
  let relationships = data?.agencyHumanRelationships || [];
  /** If the feature flag is not flipped, we want to show all relationships on one tab */
  if (ffAddPotentialConnections012024)
    relationships = relationships.filter(({ kinshipRelationship }) =>
      tab === "potentialConnections"
        ? [UNKNOWN, NO_RELATIONSHIP].includes(kinshipRelationship)
        : ![UNKNOWN, NO_RELATIONSHIP].includes(kinshipRelationship)
    );

  const [deleteRelationship, { loading: deleting }] = useMutation(
    DeleteRelationship,
    {
      refetchQueries,
      onCompleted: () => setRelationshipIdToDelete(undefined),
    }
  );
  const [relationshipIdToDelete, setRelationshipIdToDelete] = useState();
  const [relationshipToView, setRelationshipToView] = useState();

  const { data: { can: canCreateBulkOutreachCampaignQuery } = {} } = useQuery(
    CanCreateBulkOutreachCampaignQuery,
    {
      variables: {
        resource: "agency_human",
        action: "manage_family_finding_bulk_outreach_campaign",
      },
    }
  );

  const [createClearPersonSearch, { loading: creatingSearch }] = useMutation(
    CreateClearPersonSearch,
    {
      onCompleted: mutationData => {
        window.location = familyFindingClearPersonSearchPath(
          mutationData.createClearPersonSearch.search.id
        );
      },
    }
  );

  const permissions = useAgencyHumanPermissions(keystoneAgencyHumanId);

  const canCreateSearch =
    permissions.mayCreatePotentialKinSearch() && ffIntegratedSearch;

  const bulkActionLinks = [
    ...(ffBulkOutreachPrint
      ? [
          {
            description: relationshipT("print_letters"),
            href: newFamilyFindingBulkOutreachCampaignPath({
              [B64PARAMS]: addEncodedBase64SearchParams([
                {
                  attribute: "childAgencyHumanId",
                  value: keystoneAgencyHumanId,
                },
                {
                  attribute: "channel",
                  value: FAMILY_FINDING_BULK_OUTREACH_CAMPAIGN_PRINT,
                },
              ]),
            }),
          },
        ]
      : []),
    ...(ffBulkOutreachEmail
      ? [
          {
            description: relationshipT("send_emails"),
            href: newFamilyFindingBulkOutreachCampaignPath({
              [B64PARAMS]: addEncodedBase64SearchParams([
                {
                  attribute: "childAgencyHumanId",
                  value: keystoneAgencyHumanId,
                },
                {
                  attribute: "channel",
                  value: FAMILY_FINDING_BULK_OUTREACH_CAMPAIGN_EMAIL,
                },
              ]),
            }),
          },
        ]
      : []),
  ];

  return (
    <LoadingOverlay active={creatingSearch}>
      <ConfirmationModal
        onSubmit={async () => {
          await createClearPersonSearch({
            variables: { agencyHumanId: idForSearchConfirmationModal },
          });
          setIdForSearchConfirmationModal();
        }}
        onCancel={() => setIdForSearchConfirmationModal()}
        hidden={!idForSearchConfirmationModal}
      />
      <Flex column gap="300">
        <ViewRelationshipModal
          keystoneAgencyHumanId={keystoneAgencyHumanId}
          relationship={relationshipToView}
          setRelationshipToView={setRelationshipToView}
        />
        <Modal
          title={relationshipT("delete_modal.confirm_delete")}
          submitting={deleting}
          submitDangerButton
          submitText={relationshipT("delete_modal.yes_delete")}
          submittingText={relationshipT("delete_modal.deleting")}
          onCancel={() => setRelationshipIdToDelete()}
          onSubmit={() =>
            deleteRelationship({ variables: { id: relationshipIdToDelete } })
          }
          hidden={isNil(relationshipIdToDelete)}
        >
          <Flex column>
            <FlexItem>{relationshipT("delete_modal.are_you_sure")}</FlexItem>
            <FlexItem>
              {relationshipT("delete_modal.delete_help_text")}
            </FlexItem>
          </Flex>
        </Modal>
        <ArrayDataTable
          title={relationshipT("relationships")}
          loading={loading}
          rowSelectProperty={
            canCreateBulkOutreachCampaignQuery &&
            (ffBulkOutreachPrint || ffBulkOutreachEmail) &&
            familyFindingEnabled
              ? rowData =>
                  determineName({ keystoneAgencyHumanId, ...rowData }).name
              : undefined
          }
          actions={
            <Flex column justify="end">
              <Flex>
                <If condition={permissions.mayCreateRelationship()}>
                  <Button
                    variant={isEmpty(selectedIds) ? "primary" : "secondary"}
                    href={addPersonFormPath}
                  >
                    {relationshipT("add_connection")}
                  </Button>
                </If>
                <If
                  condition={
                    (ffBulkOutreachPrint || ffBulkOutreachEmail) &&
                    canCreateBulkOutreachCampaignQuery &&
                    familyFindingEnabled
                  }
                >
                  <ButtonMenu
                    rightAligned
                    buttonProps={{
                      children: relationshipT("bulk_actions"),
                      disabled: isEmpty(selectedIds),
                      icon: Icons.ChevronDown,
                      iconOnRight: true,
                    }}
                    linkItems={bulkActionLinks}
                  />
                </If>
                <If condition={ffGenogram102024 && familyFindingEnabled}>
                  <Toggle
                    LeftIcon={Icons.List}
                    RightIcon={Icons.Sitemap}
                    leftDescription="View Table"
                    rightDescription="View Genogram"
                    leftView="table"
                    rightView="genogram"
                  />
                </If>
              </Flex>
            </Flex>
          }
          columns={useMemo(
            () =>
              getColumns({
                tab,
                keystoneAgencyHumanId,
                setRelationshipIdToDelete,
                setRelationshipToView,
                canCreateSearch,
                setIdForSearchConfirmationModal,
              }),
            [
              tab,
              keystoneAgencyHumanId,
              canCreateSearch,
              setIdForSearchConfirmationModal,
            ]
          )}
          data={relationships}
        />
        <Flex justify="center">
          <Button
            variant="secondary"
            href={`${downloadRelationshipsCsvPath}?${paramsForCSV}`}
          >
            {I18n.t("common.download_csv")}
          </Button>
        </Flex>
      </Flex>
    </LoadingOverlay>
  );
};
RelationshipsTable.propTypes = {
  tab: PropTypes.string,
  /** Agencies with Prevention enabled can see the relationship table,
   * but cannot use bulk actions or view the genogram
   */
  familyFindingEnabled: PropTypes.bool,
  addPersonFormPath: PropTypes.string,
  keystoneAgencyHumanId: PropTypes.number.isRequired,
  downloadRelationshipsCsvPath: PropTypes.string,
};

export default RelationshipsTable;
