import { Button, Flex, toast } from "@heart/components";
import { Microphone, Pause, Stop } from "@heart/components/icon/Icon";
import { useToggle } from "@react-hookz/web";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";

import { translationWithRoot } from "@components/T";
import useVolumePulser from "@components/voice_navigation/common/useVolumePulser";

import styles from "./TranscriptionInput.module.scss";
import TranscriptionRecorder from "./TranscriptionRecorder";

const { t, T } = translationWithRoot("transcription.transcription_input");

/**
 * Displays a microphone icon button that allows the user to record
 * longer audio input for transcriptions. The recorder can be paused
 * and resumed as well. Emits the final recording via the onNewTranscription
 * callback.
 *
 * isRecordingAllowed is an optional async callback that can be used to
 * check if recording is allowed before starting the recording. This callback
 * may be used for example to prevent re-recording over a previous recording
 * if the mounting component only has a single slot for a recording (e.g. as
 * the current transcription form does).
 */
const TranscriptionInput = ({ onNewTranscription, isRecordingAllowed }) => {
  const [transcriptionRecorder, setTranscriptionRecorder] = useState(null);
  const [paused, togglePaused] = useToggle(false);
  const { onAudioActivity, PulserContainer } = useVolumePulser();

  const startRecording = useCallback(async () => {
    if (isRecordingAllowed && !(await isRecordingAllowed())) return;

    if (!TranscriptionRecorder.supportsAudioRecording()) {
      toast.negative(t("audio_not_supported"));
      return;
    }

    if (transcriptionRecorder) {
      await transcriptionRecorder.stop();
    }

    setTranscriptionRecorder(
      await TranscriptionRecorder.createRecorder({ onSpeech: onAudioActivity })
    );
  }, [transcriptionRecorder, onAudioActivity, isRecordingAllowed]);

  // stop recording when component unmounts
  useEffect(() => {
    const cleanup = async () => {
      await transcriptionRecorder?.stop();
    };

    return cleanup;
  }, [transcriptionRecorder]);

  const stopRecording = useCallback(async () => {
    onNewTranscription(await transcriptionRecorder.stop());
    setTranscriptionRecorder(null);
  }, [transcriptionRecorder, onNewTranscription]);

  const pauseRecording = useCallback(() => {
    if (!transcriptionRecorder) return;

    if (paused) {
      transcriptionRecorder.resume();
      transcriptionRecorder.onSpeech = onAudioActivity;
    } else {
      transcriptionRecorder.pause();
      transcriptionRecorder.onSpeech = () => {};
    }

    togglePaused();
  }, [paused, togglePaused, transcriptionRecorder, onAudioActivity]);

  return (
    <Flex column>
      <Flex row align="center">
        <PulserContainer
          buttonContainerClassName={classNames(
            styles.recordingButtonContainer,
            { [styles.pausedButton]: Boolean(transcriptionRecorder) }
          )}
        >
          <Button
            onClick={transcriptionRecorder ? stopRecording : startRecording}
            icon={transcriptionRecorder ? Stop : Microphone}
            description={
              transcriptionRecorder ? t("stop_recording") : t("start_recording")
            }
            round
          />
        </PulserContainer>
        <If condition={transcriptionRecorder}>
          <Flex column className={styles.pauseButtonContainer}>
            <div>
              <Button
                onClick={pauseRecording}
                icon={Pause}
                description={
                  paused ? t("resume_recording") : t("pause_recording")
                }
                variant="secondary"
              />
            </div>
            <T t={paused ? "resume_recording" : "pause_recording"} />
          </Flex>
        </If>
      </Flex>
      <If condition={transcriptionRecorder}>
        <T t={paused ? "paused" : "recording"} />
      </If>
    </Flex>
  );
};

TranscriptionInput.propTypes = {
  /**
   * Callback function to call when a new recording is available with the recording File.
   */
  onNewTranscription: PropTypes.func.isRequired,
  /**
   * Callback function to call before starting the recording.
   * If it returns false, the recording will not start.
   * Please make try to cache this callback using useCallback.
   */
  isRecordingAllowed: PropTypes.func,
};

export default TranscriptionInput;
