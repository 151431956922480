import { useMutation, useQuery } from "@apollo/client";
import {
  Alert,
  Button,
  ContainsTrustedHTML,
  Flex,
  LoadingOverlay,
  Upload,
} from "@heart/components";
import classnames from "classnames";
import { isEmpty, remove } from "lodash";
import PropTypes from "prop-types";
import { useState } from "react";

import ProgressArc from "@components/ProgressArc";
import T from "@components/T";

import CreateApplicationUploadedRecord from "@graphql/mutations/CreateApplicationUploadedRecord.graphql";
import CreateUploadTypeOverride from "@graphql/mutations/CreateUploadTypeOverride.graphql";
import DeleteOverrideRecord from "@graphql/mutations/DeleteOverrideRecord.graphql";
import DeleteUploadedRecordAttachment from "@graphql/mutations/DeleteUploadedRecordAttachment.graphql";
import ApplicationUploadTypeRequirementFulfillment from "@graphql/queries/ApplicationUploadTypeRequirementFulfillment.graphql";

import BintiPropTypes from "@lib/BintiPropTypes";
import { typeEq } from "@lib/graphqlHelpers";
import { isOverridableForReason } from "@lib/overridableHelpers";
import useFeatureFlag from "@lib/useFeatureFlag";
import useShrine from "@lib/useShrine";

import EndUserUploadTypeOverride from "./EndUserUploadTypeOverride";
import EndUserUploadTypeRecords from "./EndUserUploadTypeRecords";
import styles from "./EndUserUploadTypeTable.module.scss";

// If we return any non-empty string, the browser shows "Are you sure you
// want to leave this page?" The message is not customizable for security
// reasons.
//
// https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
const confirmNoExpirationDate = () => " ";

const isFulfilledByHiddenRecords = ({
  isFulfilled,
  isFulfilledByTheseRecords,
}) => isFulfilled && !isFulfilledByTheseRecords;

const expirationDateNeeded = ({ requirement: { uploadType }, records }) =>
  uploadType.requiresExpirationDate &&
  records.some(
    record =>
      !typeEq("OverrideRecord", record) && isEmpty(record.expirationDate)
  );

const ActionNeededSoonBanner = ({ fulfillment }) => (
  <div className={styles.actionNeededSoonUploadTypeMessage}>
    <i
      className={classnames("fa fa-exclamation-triangle", styles.noticeIcon)}
      aria-hidden="true"
    />
    <T
      t="end_user.common.action_needed_soon_html"
      date={I18n.l(fulfillment.dateActionNeededBy)}
    />
  </div>
);
ActionNeededSoonBanner.propTypes = { fulfillment: PropTypes.object.isRequired };

const ActionCriticalBanner = ({ fulfillment }) => (
  <div className={styles.actionCriticalUploadTypeMessage}>
    <i
      className={classnames("fa fa-exclamation-triangle", styles.noticeIcon)}
      aria-hidden="true"
    />
    <T
      t="end_user.common.action_critical_html"
      date={I18n.l(fulfillment.dateActionNeededBy)}
    />
  </div>
);
ActionCriticalBanner.propTypes = { fulfillment: PropTypes.object.isRequired };

const CompletedByTheseRecordsBanner = () => (
  <div className={styles.completeUploadTypeMessage}>
    <div className={styles.progressArc}>
      <ProgressArc progress={100} />
    </div>
    <T t="end_user.end_user_upload_type.completed_by_shown_records" />
  </div>
);

const FulfilledByTheseRecordsBanner = () => (
  <div className={styles.completeUploadTypeMessage}>
    <div className={styles.progressArc}>
      <ProgressArc progress={100} />
    </div>
    <T t="end_user.end_user_upload_type.completed_by_hidden_records" />
  </div>
);

const ExpirationDateNeededBanner = () => (
  <div className={styles.recordDateNeeded}>
    <T t="end_user.end_user_upload_type.expiration_date_needed" />
  </div>
);

const Banner = ({ fulfillment }) => {
  if (fulfillment.isActionNeededSoon) {
    return <ActionNeededSoonBanner fulfillment={fulfillment} />;
  }

  if (fulfillment.isActionCritical) {
    return <ActionCriticalBanner fulfillment={fulfillment} />;
  }

  if (fulfillment.isFulfilledByTheseRecords) {
    return <CompletedByTheseRecordsBanner />;
  }

  if (isFulfilledByHiddenRecords(fulfillment)) {
    return <FulfilledByTheseRecordsBanner />;
  }

  if (expirationDateNeeded(fulfillment)) {
    return <ExpirationDateNeededBanner />;
  }

  return false;
};
Banner.propTypes = { fulfillment: PropTypes.object.isRequired };

/**
 * Applicant-side root component for displaying a page where a user can
 * upload or override an upload type.
 */
const EndUserUploadType = ({
  applicationId,
  uploadTypeSlug,
  userAgencyProfileId,
  providerRole,
  readOnly,
  backLink,
}) => {
  const { loading, error, data } = useQuery(
    ApplicationUploadTypeRequirementFulfillment,
    {
      variables: { applicationId, uploadTypeSlug, userAgencyProfileId },
    }
  );
  const [showAlert, setShowAlert] = useState(false);

  const [createUploadedRecord, { loading: createUploadedRecordLoading }] =
    useMutation(CreateApplicationUploadedRecord);

  const [
    deleteUploadedRecordAttachment,
    { loading: deleteUploadedRecordAttachmentLoading },
  ] = useMutation(DeleteUploadedRecordAttachment);

  const [
    createUploadTypeOverride,
    { loading: createUploadTypeOverrideLoading },
  ] = useMutation(CreateUploadTypeOverride);

  const [deleteOverrideRecord, { loading: deleteOverrideRecordLoading }] =
    useMutation(DeleteOverrideRecord);
  const { flag: ffShrineSdv2Uploads042024, loading: flagLoading } =
    useFeatureFlag("ff_shrine_sdv2_uploads_04_2024");

  const { prepareShrineFiles, ShrineErrorAlert } = useShrine();

  if (loading || flagLoading) {
    return <LoadingOverlay active={true} />;
  }

  if (error) {
    return `Error: ${JSON.stringify(error)}`;
  }

  const {
    applicationUploadTypeRequirementFulfillment: fulfillment,
    applicationUploadTypeRequirementFulfillment: {
      requirement,
      requirement: { title, uploadType },
      records,
    },
  } = data;

  const anythingLoading =
    createUploadedRecordLoading ||
    deleteUploadedRecordAttachmentLoading ||
    createUploadTypeOverrideLoading ||
    deleteOverrideRecordLoading;

  const onDrop = async files => {
    // files were rejected if length is 0
    if (files.length === 0) {
      setShowAlert(true);

      return;
    }

    let fileVariables;

    if (ffShrineSdv2Uploads042024) {
      fileVariables = {
        files: [],
        shrineFiles: await prepareShrineFiles(files),
      };
    } else {
      fileVariables = { files };
    }

    await createUploadedRecord({
      variables: {
        applicationId,
        uploadTypeSlug: requirement.uploadType.slug,
        userAgencyProfileId: requirement.userAgencyProfile.id,
        ...fileVariables,
      },
    });
  };

  const overrideRecord = records.find(typeEq("OverrideRecord"));

  const showOverride =
    (overrideRecord ||
      (isOverridableForReason({
        requirement,
        providerRole,
        reason: "notApplicable",
      }) &&
        isEmpty(records))) &&
    !isFulfilledByHiddenRecords(fulfillment);

  // check with the applicant if they want to leave when there's a missing
  // expiration date.
  if (expirationDateNeeded(fulfillment)) {
    if (!Binti.onBeforeUnloadCallbacks.includes(confirmNoExpirationDate)) {
      Binti.onBeforeUnloadCallbacks.push(confirmNoExpirationDate);
    }
  } else {
    remove(Binti.onBeforeUnloadCallbacks, confirmNoExpirationDate);
  }

  return (
    <Flex column gap="200" align="start">
      <div
        className={classnames(styles.uploadType, {
          [styles.backgroundLoading]: anythingLoading,
        })}
        data-testid="end-user-upload-type"
      >
        <h1>{title}</h1>
        <Banner fulfillment={fulfillment} />
        {!overrideRecord && (
          <div className={styles.hasRecordsArea}>
            <ContainsTrustedHTML
              as="div"
              html={uploadType.descriptionTranslations[I18n.locale]}
              trustedSource={"TAM-configured uploadType descriptions"}
              className="space-below-2"
            />
            <Upload multiple onUpload={onDrop} />
            <EndUserUploadTypeRecords
              applicationId={applicationId}
              deleteUploadedRecordAttachment={deleteUploadedRecordAttachment}
              records={records.filter(typeEq("UploadedRecord"))}
              uploadType={uploadType}
            />
          </div>
        )}
        {showOverride && (
          <EndUserUploadTypeOverride
            record={overrideRecord}
            {...{
              applicationId,
              createUploadTypeOverride,
              deleteOverrideRecord,
              requirement,
              providerRole,
              readOnly,
            }}
          />
        )}
      </div>

      <ShrineErrorAlert />
      <Alert
        hidden={!showAlert}
        isAlert={true}
        title={I18n.t("javascript.components.common.alert")}
        onSubmit={() => setShowAlert(false)}
        submitText={I18n.t("attachments.errors.ok")}
      >
        {I18n.t("attachments.errors.unpermitted_format")}
      </Alert>
      <If condition={backLink}>
        <Button disabled={anythingLoading} href={backLink}>
          {I18n.t("helpers.questionnaire_responses_helper.save_and_continue")}
        </Button>
      </If>
    </Flex>
  );
};

EndUserUploadType.propTypes = {
  applicationId: BintiPropTypes.ID.isRequired,
  uploadTypeSlug: PropTypes.string.isRequired,
  userAgencyProfileId: BintiPropTypes.ID,
  providerRole: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  backLink: PropTypes.string,
};

export default EndUserUploadType;
